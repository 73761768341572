<script setup>
import AppHeader from "@/components/AppHeader.vue";
import InputField from "@/components/Fields/InputField";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { Switch } from "@headlessui/vue";
import ShareIcon from "@/components/Icons/ShareIcon.vue";
import api from "@/api";
import { db } from "@/localdatabase/dbConfig";
import Danger from "@/components/ToastIcons/ErrorIcon.vue";
import SuccessIcon from "@/components/ToastIcons/SuccessIcon.vue";
import { onMounted, ref } from "vue";
import NewField from "@/components/Icons/NewField.vue";
import SelectDropdown from "@/components/Fields/SelectDropdown.vue";
import SixDots from "@/components/Icons/SixDots.vue";
const emits = defineEmits(['close:popup', 'setting:selected', 'saved:field']);
const props = defineProps(['fieldType']);
var router = useRouter();
const selectedMember = ref(null);
let teams_id = ref(null);
let currentTeam = ref(null);
let data = ref(null);
const toast = useToast();
const copyFromOtherTeam = ref(false);
const fieldType = ref(null);
const teams = ref([]);
const fieldNameText = ref("");
const fieldNameDesc = ref("");
const fieldOptionText = ref("");
const optionsArray = ref([]);
onMounted(async () => {
  const user_id = localStorage.getItem("workspace")
  data.value = JSON.parse(user_id).user_id;
  teams_id.value = router.currentRoute.value.params.id;
  await fetchTeams();
});

function closePopup() {
  emits('close:popup', false);
}

async function fetchTeams() {
  const teamsTemp = await db.teams.toArray();
  for (let team of teamsTemp) {
    teams.value.push({
      id: team.id,
      label: team.name
    });
  }
}

function saveField() {
  api
    .post(`teams/${teams_id.value}/fields`, {
      name: fieldNameText.value,
      description: fieldNameDesc.value,
      type: props.fieldType,
      team_id: teams_id.value,
      options: optionsArray.value
    })
    .then((response) => {
      if (response.status == 201) {
        toast("Field is saved successfully", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: SuccessIcon,
          rtl: false,
        });
        closePopup();
        emits('saved:field', response.data);
      }
      else {
        toast("Field cannot be saved. Please try again later", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      }
    });
}

function goToNextStep() {
  saveField();
}

function addOption() {
  optionsArray.value.push({
    value: fieldOptionText.value,
    order: optionsArray.value.length
  });
  fieldOptionText.value = "";
}

function removeOption(index) {
  optionsArray.value.splice(index, 1);
}
</script>
<template>
  <i class="fa fa-close custom-fa-close" style="text-align: center; padding: 0; top: 1rem; right: 1rem;"
    @click="closePopup"></i>
  <div class="row">
    <div class="col-md-10 mt-3 p-0">
      <h2>
        <NewField />
        {{ props.fieldType == 'radio' ? 'New Radio Button Field' : 'New Dropdown Field' }}
      </h2>
    </div>
    <div class="col-md-2 mt-3 p-0" style="text-align: center;">
      <div class="iconBorder" style="width: 35px; padding:6px 5px;">
        <ShareIcon />
      </div>
    </div>
  </div>

  <div class="fields-wrapper" v-if="!copyFromOtherTeam">
    <div class="row">
      <div class="col-md-8 mt-3">
        <label for="fieldTypeText">Name</label>
        <InputField v-model="fieldNameText" :placeholder="'Enter'" />
      </div>
      <div class="col-md-4 mt-3">
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mt-3">
        <label for="fieldTypeText">Description</label>
        <textarea v-model="fieldNameDesc"
          style="margin-top: 0; border: 1px solid #c4c4c4; border-radius: 8px; "></textarea>
      </div>
      <div class="col-md-4 mt-3"></div>
    </div>
    <div class="row">
      <div class="col-md-8 mt-3">
        <label for="fieldTypeText">Options</label>
        <InputField v-model="fieldOptionText" :placeholder="'Enter'" />
      </div>
      <div class="col-md-4 mt-3">
        <label for="fieldTypeText" style="display: block;">&nbsp;</label>
        <button @click="addOption" class="custom-primary-btn custom-btn-back-class" style="height: 46px;">Add</button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8 mt-3">
        <div class="row" v-for="(item, index) in optionsArray" :key="index">
          <div class="col-md-1" style="text-align: center; align-content: center;">
            <SixDots style="cursor: move;" />
          </div>
          <div class="col-md-11 p-0 mt-2" style="position: relative;">
            <InputField v-model="item.value" :placeholder="'Enter'" />
            <i class="fa fa-close custom-fa-close" style="text-align: center; padding: 0; top: 0.7rem; right: 0.7rem;"
              @click="removeOption(index)"></i>
          </div>
        </div>
      </div>
      <div class="col-md-4 mt-3">
        <label for="fieldTypeText" style="display: block;">&nbsp;</label>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="col-12 mt-4">
      <button class="custom-primary-btn" type="button" style="float: right;" @click="goToNextStep">
        {{ copyFromOtherTeam || fieldType == 'date' || fieldType == 'text' ? 'Create' : 'Next' }}
      </button>
      <button type="button" class="custom-secondary-btn me-2" style="float: right;" @click="closePopup">
        Close
      </button>
    </div>
  </div>

</template>
<style lang="scss">
input[type=radio] {
  accent-color: #f96332;
}

.custom-btn-back-class {
  background-color: #FFF5F2;
  color: #f96332;
  border: 1px solid #FFF5F2;
  height: 46px !important;
}

textarea {
  resize: none;
}

.tab-wrapper {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(249, 99, 50, 0.1);

  .thead-view {
    .head-row-view {
      height: 60px;

      th {
        background-color: #eaeaea;
        font-weight: 500;
      }

      th:first-child {
        border-radius: 8px 0 0 0;
      }

      th:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  .tab-body {
    .row-view {
      height: 60px;
    }
  }
}
</style>