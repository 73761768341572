<script setup>
import AppHeader from "@/components/AppHeader.vue";
import InputField from "@/components/Fields/InputField";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { Switch } from "@headlessui/vue";
import ShareIcon from "@/components/Icons/ShareIcon.vue";
import api from "@/api";
import { db } from "@/localdatabase/dbConfig";
import { onMounted, ref } from "vue";
import NewField from "@/components/Icons/NewField.vue";
import SelectDropdown from "@/components/Fields/SelectDropdown.vue";
const emits = defineEmits(['close:popup', 'setting:selected']);
var router = useRouter();
const selectedMember = ref(null);
let teams_id = ref(null);
let currentTeam = ref(null);
let data = ref(null);
const toast = useToast();
const copyFromOtherTeam = ref(false);
const teams = ref([]);
onMounted(async () => {
  const user_id = localStorage.getItem("workspace")
  data.value = JSON.parse(user_id).user_id;
  teams_id.value = router.currentRoute.value.params.id;
  await fetchTeams();
});

function closePopup() {
  emits('close:popup', false);
}

async function fetchTeams() {
  const teamsTemp = await db.teams.toArray();
  for (let team of teamsTemp) {
    teams.value.push({
      id: team.id,
      label: team.name
    });
  }
}

function goToNextStep() {
  if (copyFromOtherTeam.value) {
    console.log("Do Nothing For now...");
  }
  else {
    emits('setting:selected', true);
    closePopup();
  }
}

function selectTeam(team) {

}
</script>
<template>
  <i class="fa fa-close custom-fa-close" style="text-align: center; padding: 0; top: 1rem; right: 1rem;"
    @click="closePopup"></i>
  <div class="row">
    <div class="col-md-10 mt-3 p-0">
      <h2>
        <NewField />
        New Field
      </h2>
    </div>
    <div class="col-md-2 mt-3 p-0" style="text-align: center;">
      <div class="iconBorder" style="width: 35px; padding:6px 5px;">
        <ShareIcon />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 p-0 mt-4">
      <span>Copy existing field from another team</span>
    </div>
    <div class="col-md-2 p-0 mt-4">
      <Switch v-model="copyFromOtherTeam" :style="copyFromOtherTeam
      ? 'background: #F96332;'
      : 'background: #EAEAEA;'
      " style="" class="custom-switch transition-colors duration-200 ease-in-out">
        <span class="sr-only">Copy existing field from another team</span>
        <span aria-hidden="true" :class="copyFromOtherTeam ? 'translate-x-9' : 'translate-x-0'
      " class="custom-transform custom-transition duration-200 ease-in-out"></span>
      </Switch>
    </div>
  </div>
  <div class="row" v-if="teams.length > 0 && copyFromOtherTeam">
    <div class="col-md-6 mt-3 p-0">
      <SelectDropdown :items="teams" :displayKey="'label'" :placeholder="'Choose Team'" :initialSelectedValue="null"
        @onSelectValue="selectTeam">
      </SelectDropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-4">
      <button class="custom-primary-btn" type="button" style="float: right;" @click="goToNextStep">
        {{ copyFromOtherTeam ? 'Create' : 'Next' }}
      </button>
      <button type="button" class="custom-secondary-btn me-2" style="float: right;" @click="closePopup">
        Close
      </button>
    </div>
  </div>

</template>
<style lang="scss">
.tab-wrapper {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(249, 99, 50, 0.1);

  .thead-view {
    .head-row-view {
      height: 60px;

      th {
        background-color: #eaeaea;
        font-weight: 500;
      }

      th:first-child {
        border-radius: 8px 0 0 0;
      }

      th:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  .tab-body {
    .row-view {
      height: 60px;
    }
  }
}
</style>
