<script setup>
import { ColorPicker } from 'vue-accessible-color-picker';
import { ref, inject } from 'vue';
import InputField from './InputField.vue';
import { onMounted } from 'vue';
const emits = defineEmits(['update:showPopup', 'update:linkSelected']);
const props = defineProps(['highlightedText']);
const text = ref('');
const link = ref('');

onMounted(() => {
  text.value = props.highlightedText;
});
function closePopup() {
  emits('update:showPopup', false);
}
function selectLinkAndClose() {
  emits('update:linkSelected', text.value, link.value);
  closePopup();
}
</script>
<template>
  <div>
    <div class="d-flex align-items-center pt-3 mb-4">
      <h2>
        Add Link
      </h2>
      <i class="fa fa-close custom-fa-close" @click="closePopup"></i>
    </div>
    <label>Text</label>
    <InputField :icon="''" placeholder="Enter text for link" type="text" id="link_text" ref="inputField" v-model="text">
    </InputField><br />
    <label>Link</label>
    <InputField :icon="''" placeholder="Enter a URL" type="url" id="link_url" ref="inputField" v-model="link">
    </InputField>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-center mt-3">
        <button type="button" class="custom-secondary-btn me-4 " @click="closePopup">
          Cancel
        </button>
        <button class="custom-primary-btn" type="button" @click="selectLinkAndClose">
          Save
        </button>

      </div>
    </div>
  </div>
</template>