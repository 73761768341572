<script setup>
import InputField from '@/components/Fields/InputField.vue';
import { onMounted } from 'vue';
import { ref } from 'vue'
import { useRoute } from 'vue-router';
import api from '@/api';
import DisplayAvatar from '@/components/Pages/Profile/DisplayAvatar.vue';
import '@vuepic/vue-datepicker/dist/main.css'
import { db } from '@/localdatabase/dbConfig';
import { watch } from 'vue';
const props = defineProps(['teamId'])
const route = useRoute()
const emits = defineEmits(['update:showPopup', 'selected:item']);
const selectedPriorityDropdown = ref(null);
const name = ref('');
const teamMembers = ref([]);
onMounted(() => {
  //const teamId = localStorage.getItem('teamId');
  getTeamData();
});

watch(() => props.teamId, (first, second) => {
  getTeamData();
});

function getTeamData() {
  if (props.teamId == null) {
    db.members.toArray().then((response) => {
      let addedMembers = [];
      for (let mem of response) {
        if (!addedMembers.includes(mem.user_id)) {
          teamMembers.value.push(mem);
          addedMembers.push(mem.user_id);
        }
      }
    });
  }
  else {
    db.members.where({ team_id: parseInt(props.teamId) }).toArray().then((response) => {
      let addedMembers = [];
      for (let mem of response) {
        if (!addedMembers.includes(mem.user_id)) {
          teamMembers.value.push(mem);
          addedMembers.push(mem.user_id);
        }
      }
    });
  }
}

function selectAssignee(item) {
  item['member_id'] = item.id;
  item.id = item.user_id;
  console.log(item);
  emits('selected:item', item);
  closePopup();
}

function closePopup() {
  emits('update:showPopup', false);
}
const justMounted = ref(0);
</script>

<template>
  <div class="wrapper" style="min-width:180px;">
    <!-- Input field for searching members -->
    <InputField placeholder="Search" type="text" :svgIconDropDown="true"></InputField>
    <!-- List of members with checkboxes -->
    <ul class="mb-0" :key="props.teamId">
      <li v-for="member in teamMembers" :key="member.id">
        <label @click="selectAssignee(member)">
          <div class="row">
            <div class="d-flex col align-items-center">
              <DisplayAvatar :avatar="member.avatar" />
              {{ (member == null) ? '' : ((member['first_name'] == '' || member['first_name'] == null) &&
      (member['last_name'] == '' || member['last_name'] == null)) ?
      member['name'] : member['first_name'] + ' ' + member['last_name'] }}
            </div>
          </div>
        </label>
      </li>
    </ul>

  </div>
</template>

<style scoped>
.issuesListAssigneeWrapper ull.dropdown-menu {
  min-height: 100px !important;
}

.gray-color {
  color: #7B8497;
  font-size: 14px;
}

.mr-1 {
  margin-right: 1rem;
}

.subtask-button {
  width: 80%;
  float: right;
}

.white-btn {
  background: #FFF;
  font-weight: normal;
  color: #000;
  margin-left: 1rem;
}

.white-btn:hover {
  color: #000;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  width: 268px;
  min-height: 200px;
  padding: 10px 14px;
  border-radius: 12px;
  border: 1px solid #C4C4CA;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.dropdown-wrapper {
  position: relative;
}

.dropdown-wrapper .dropdown-menu {
  top: 2.5rem !important;
  left: 0 !important;
  width: auto !important;
}

.dropdown-wrapper .dropdown-menu.position-bottom {
  top: auto !important;
  bottom: 110% !important;
}

.dropdown-wrapper .subtask {
  top: -1000% !important;
  left: -400% !important;
  min-height: 150px !important;
}

/* Add the 'show' class when isDropdownOpen is true */
.dropdown-menu.show {
  display: block;
}

.position-top {
  top: 110%;
  max-height: 341px;
}

.position-bottom {
  bottom: 110%;
  max-height: 392px;
}

.dropdown-menu input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: none;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
}

.dropdown-menu ul li label {
  display: block;
  margin-bottom: 5px;
}

/* Style the checkbox however you like */
.dropdown-menu ul li input[type="checkbox"] {
  margin-right: 5px;
}

.selected-leader {
  background: #EAEAEA;
}

.popup {
  width: 50% !important;
}

.fa-close {
  position: absolute;
  right: 3rem;
  top: 2rem;
  background: #999;
  color: #FFF;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}

.more {
  background-color: #EAEAEA;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  min-width: 60px;
}
</style>