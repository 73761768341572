<script setup>
import { ColorPicker } from 'vue-accessible-color-picker';
import { ref, inject, onMounted } from 'vue'
const emits = defineEmits(['update:showPopup', 'update:colorSelected']);
let selectedColor = ref('');
const maxColorBoxes = ref(72);
const colorListArray = ref([
  '#FF0000', '#00FFFF', '#00b300', '#000000', '#7B3F00', '#ffff00', '#ff7900', '#800080',
  '#8B0000', '#000080', '#006400', '#2F4F4F', '#8A3324', '#FFEA00', '#ff4d03', '#942192',
  '#C00000', '#0433FF', '#8DB600', '#797979', '#8b6c5c', '#FFD700', '#FD673A', '#8968CD',
  '#DC143C', '#00BFFF', '#60FE00', '#A9A9A9', '#AA7942', '#E4D00A', '#FF9300', '#A865B5',
  '#F98B85', '#D8F3FD', '#D4F7C5', '#DCDCDC', '#EFDECD', '#FFFDD0', '#FFD68A', '#CCCCFF']);

function selectColor(color) {
  selectedColor.value = color;
  emits('update:colorSelected', selectedColor.value);
}

onMounted(() => {
  //generateColorPalette();
});

// Generate color palette function
function generateColorPalette() {
  for (let i = 0; i < maxColorBoxes.value; i++) {
    const randomHexColor = '#' +
      Math.floor(Math.random()
        * 0xffffff).toString(16).padStart(6, '0');
    colorListArray.value.push(randomHexColor);
  }
}


</script>
<template>
  <div class="row" v-if="colorListArray.length > 0">
    <div class="colorBox" @click="selectColor(value)" :style="{ backgroundColor: value }"
      v-for="(value, index) in colorListArray" :key="index">
      <i class="fa-solid fa-check tick" v-if="selectedColor == value"></i>
    </div>
  </div>
</template>
<style>
.colorBox {
  width: 20px;
  height: 20px;
  padding: 0 !important;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
}

.colorBox .tick {
  color: #FFFFFF;
}
</style>