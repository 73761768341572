<script setup>
import AppHeader from '@/components/AppHeader.vue';
import ShareIcon from "@/components/Icons/ShareIcon.vue";
import { Container, Draggable } from "vue-dndrop";
import CupIcon from "@/components/Icons/CupIcon.vue";
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import IssueTypeTabComponent from './IssueTypeTabComponent.vue';
import api from "../../../api";
import { useToast } from "vue-toastification";
import SuccessIcon from "../../ToastIcons/SuccessIcon";
import Danger from '../../ToastIcons/ErrorIcon.vue';
import SelectDropdown from '@/components/Fields/SelectDropdown.vue';
import FieldTypeOptions from "@/components/Pages/Teams/FieldPopups/FieldTypeOptions.vue";
import CreateField from "@/components/Pages/Teams/FieldPopups/CreateField.vue";
import AvatarModal from "@/components/Fields/AvatarModal";
import ColorComponent from "@/components/Fields/ColorComponent.vue";
import { useRouter } from "vue-router";
var route = useRouter();
var toast = useToast();
var router = useRouter();

import { onMounted, ref, } from "vue";
import { db } from '@/localdatabase/dbConfig';
const props = defineProps({
    modelValue: Boolean,
})
const name = ref("");
const include_sections = ref([]);
const include_fields = ref([]);
const additional_fields = ref([]);
let workflows = ref([]);
const showFieldOptions = ref(false);
const fieldType = ref(null);
const showCreateField = ref(false);

let data = ref({
    id: "",
    text_cases: false,
    peer_review: false,
    assignee: false,
    esitmate: false,
    creator: false,
    labels: false,
})
onMounted(async () => {
    await setupMainColumns();
    fetchWorkflows();
});
function showFieldOptionsPopup(type) {
    fieldType.value = type;
    showCreateField.value = true;
}
const isNewIssueTypeVisible = ref(true);
const fieldConfigurations = ref({});
const excludedFields = ref({});
function fetchWorkflows() {
    var team_id = router.currentRoute.value.params.id;
    db.workflows.where({ team_id: parseInt(team_id) }).toArray().then((response) => {
        let tempArray = [];
        for (let wf of response) {
            tempArray.push(
                {
                    label: wf.name,
                    value: wf.id
                }
            );
        }
        workflows.value = tempArray;
        workflowId.value = tempArray[0].value;
    }).catch((error) => {
        console.log(error);
    });
}

function closeNewIssueType() {
    isNewIssueTypeVisible.value = false;
}

function includeMoreFields(key) {
    fieldConfigurations.value[key] = { include: true, newissue: false };
    delete excludedFields.value[key];
}

function removeField(key) {
    excludedFields.value[key] = { include: false, newissue: false };
    delete fieldConfigurations.value[key];
}

function addNewField() {
    showFieldOptions.value = true;
}

function removeAdditionalField(index) {
    additional_fields.value.splice(index, 1);
}
let workflowId = ref(0);
function workFlowSelected(item) {
    workflowId.value = item.value;
}
function saveIssueType() {
    let workspace_id = localStorage.getItem("workSpaceID");
    if (workflowId.value == null || workflowId.value == 0) {
        toast("Workflow is required for issue type", {
            position: "top-right",
            timeout: 4025,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: false,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: Danger,
            rtl: false,
        });
        return;
    }
    if (data.value.text_cases) {
        include_sections.value.push('text_cases');
    }
    if (data.value.peer_review) {
        include_sections.value.push('peer_review');
    }
    if (data.value.assignee) {
        include_fields.value.push('assignee');
    }
    if (data.value.esitmate) {
        include_fields.value.push('esitmate');
    }
    if (data.value.creator) {
        include_fields.value.push('creator');
    }
    if (data.value.labels) {
        include_fields.value.push('labels');
    }

    // Setting Fields for issueType
    let fieldConfigurationToSave = {};
    var dynamic_fields_to_send = [];
    let order = 0;
    for (let col of scene.value.children) {
        if (col.id == 'columninclude') {
            for (let child of col.children) {
                if (child.props.type == 'default') {
                    let fieldIndex = child.data;
                    if (fieldIndex == 'Divider') {
                        fieldIndex = 'Divider-' + order;
                    }
                    fieldConfigurationToSave[fieldIndex] = { include: true, newissue: child.props.showNew, order: order };
                    order++;
                }
                else {
                    let dynamicF = child.props.allFieldData;
                    dynamicF['newissue'] = child.props.showNew;
                    dynamicF['order'] = order;
                    dynamic_fields_to_send.push(dynamicF);
                    order++;
                }
            }
        }
    }
    const team_id = route.currentRoute.value.params.id;
    const issueTypeData = {
        workspace_id,
        icon: iconToBeSaved.value,
        name: name.value,
        include_sections: include_sections.value,
        team_id: team_id,
        include_fields: include_fields.value,
        fields: dynamic_fields_to_send,
        workflow_id: workflowId.value,
        field_configuration: fieldConfigurationToSave
    };
    api
        .post("teams/" + team_id + "/issuetypes", issueTypeData)
        .then((response) => {
            if (response.status === 201) {
                db.issue_types.add(response.data).then((result) => {
                    closeNewIssueType();
                    toast("Your changes are saved successfully", {
                        position: "top-right",
                        timeout: 4025,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: false,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: false,
                        icon: SuccessIcon,
                        rtl: false,
                    });
                }).catch((error) => {
                    console.log(error);
                });
            }
        })
        .catch((error) => {
            if (error) {
                return false;
            }
        });
}
const dynamicFields = ref([]);
const columns = ref([{ id: 'include', label: 'Included' }, { id: 'exclude', label: 'Excluded' }]);
const scene = ref({
    type: 'container',
    props: {
        orientation: 'horizontal',
    },
    children: [],
});
async function addAdditionalField(field) {
    const localScene = Object.assign({}, scene.value);
    for (let columnIndex = 0; columnIndex < localScene.children.length; columnIndex++) {
        if (localScene.children[columnIndex]['id'] == 'columnexclude') {
            let colDic = {
                type: 'draggable',
                id: localScene.children[columnIndex]['children'].length,
                props: {
                    className: 'card',
                    showNew: false,
                    type: field.type,
                    allFieldData: field
                },
                data: field.name
            };
            localScene.children[columnIndex]['children'].push(colDic);
            break;
        }
    }
    scene.value = localScene;
}

async function fetchDynamicFields() {
    const teams_id = router.currentRoute.value.params.id;
    const response = await api.get(`teams/${teams_id}/fields`);
    dynamicFields.value = response.data;
}
function showOnNewScreen(colIndex, cardIndex) {
    scene.value['children'][colIndex]['children'][cardIndex]['props']['showNew'] = !scene.value['children'][colIndex]['children'][cardIndex]['props']['showNew'];
}
async function setupMainColumns() {
    //Getting workspace for the fields configurations
    const workSpaceID = localStorage.getItem("workSpaceID");
    const fields = await db.workspaces.get(parseInt(workSpaceID));
    for (let key in fields.field_configuration) {
        if (fields.field_configuration[key]) {
            fieldConfigurations.value[key] = { include: fields.field_configuration[key], newissue: true };
        }
        else {
            excludedFields.value[key] = { include: fields.field_configuration[key], newissue: false };
        }
    }
    await fetchDynamicFields();
    scene.value = {
        type: 'container',
        props: {
            orientation: 'horizontal',
        },
        children: setupColumns(),
    };
}

function setupColumns() {
    let finalReturnArray = [];

    for (let col of columns.value) {
        let columnChild = {
            id: `column${col.id}`,
            type: 'container',
            name: col.label,
            props: {
                orientation: 'vertical',
                className: 'card-container',
            },
        };
        let fieldsArray = [];
        if (col.id == 'include') {
            let indexed = 0;
            for (let key in fieldConfigurations.value) {
                let colDic = {
                    type: 'draggable',
                    id: indexed,
                    props: {
                        className: 'card',
                        showNew: fieldConfigurations.value[key]['newissue'],
                        type: 'default'
                    },
                    data: key
                };
                indexed = indexed + 1;
                fieldsArray.push(colDic);
            }
            columnChild['children'] = fieldsArray;
            finalReturnArray.push(columnChild);
        }
        else {
            let indexed = 0;
            let colDic = {
                type: 'draggable',
                id: indexed,
                props: {
                    className: 'card',
                    showNew: false,
                    type: 'default'
                },
                data: 'Divider'
            };
            indexed = indexed + 1;
            fieldsArray.push(colDic);
            for (let key in excludedFields.value) {
                let colDic = {
                    type: 'draggable',
                    id: indexed,
                    props: {
                        className: 'card',
                        showNew: false,
                        type: 'default'
                    },
                    data: key
                };
                indexed = indexed + 1;
                fieldsArray.push(colDic);
            }
            for (let field of dynamicFields.value) {
                let colDic = {
                    type: 'draggable',
                    id: indexed,
                    props: {
                        className: 'card',
                        showNew: false,
                        type: field.type,
                        allFieldData: field
                    },
                    data: field.name
                };
                indexed = indexed + 1;
                fieldsArray.push(colDic);
            }
            columnChild['children'] = fieldsArray;
            finalReturnArray.push(columnChild);

        }
    }
    return finalReturnArray;
}
function getCardPayload(columnId) {
    return (index) => {
        return scene.value.children.filter((p) => p.id === columnId)[0].children[
            index
        ];
    };
}
function onColumnDrop(dropResult) {
    const localScene = Object.assign({}, scene.value);
    localScene.children = applyDrag(localScene.children, dropResult);
    scene.value = localScene;
}
let counter = 0;
function onCardDrop(columnId, dropResult) {
    if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        const localscene = Object.assign({}, scene.value);
        const column = localscene.children.filter((p) => p.id === columnId)[0];
        const columnIndex = localscene.children.indexOf(column);

        const newColumn = Object.assign({}, column);
        newColumn.children = applyDrag(newColumn.children, dropResult, columnId);
        localscene.children.splice(columnIndex, 1, newColumn);
        scene.value = localscene;
        counter++;
    }
}

function applyDrag(arr, dragResult, columnId) {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
        let itemdragged = result[removedIndex];
        if (!(itemdragged.data == 'Divider' && columnId == "columnexclude")) {
            itemToAdd = result.splice(removedIndex, 1)[0];
        }
    }

    if (addedIndex !== null) {
        if (!(itemToAdd.data == "Divider" && columnId == "columnexclude")) {
            result.splice(addedIndex, 0, itemToAdd);
        }
    }

    return result;
}

const dropPlaceholderOptions = {
    className: "drop-preview",
    animationDuration: "150",
    showOnTop: true,
};
const loading = ref(false);
const avatar = ref("");
const IsImage = ref(false);
const logo = ref(false);
const backgroundColor = ref(false);
const imageIcon = ref("");
const color = ref("");
function onInputChange() {
    if (IsImage.value) {
        avatar.value = imageIcon.value;
    } else {
        avatar.value = color.value;
    }
}
function onConvertImage(file) {
    console.log("I am in image...");
    const dataReturned = convertImage(file);
    IsImage.value = dataReturned.IsImage;
    imageIcon.value = dataReturned.imageIcon;
    console.log(imageIcon.value, "In convert Image...");
    logo.value = dataReturned.logo;
    backgroundColor.value = dataReturned.backgroundColor;
    onInputChange();
}


function onSetColor(color) {
    const dataReturned = setColor(color);
    IsImage.value = dataReturned.IsImage;
    imageIcon.value = dataReturned.imageIcon;
    logo.value = dataReturned.logo;
    backgroundColor.value = dataReturned.backgroundColor;
    color.value = dataReturned.color;
    onInputChange();
}
const file = ref(null);
function uploadAvatar(file) {
    logo.value = false;
    backgroundColor.value = false;
    IsImage.value = true;
    if (file) {
        file.value = file; // Set the file property
        imageIcon.value = URL.createObjectURL(file);
    }
}

const showAvatarModal = ref(false);
const showColorComponentForIcons = ref(false);
const iconToBeSaved = ref(null);
const iconColorToShow = ref("");
const iconToShow = ref("");
const iconCustomColor = ref("#000000");
function convertImage(file) {
    if (file) {
        const reader = new FileReader();
        reader.onload = () => {
            iconToBeSaved.value = reader.result;
        };
        reader.readAsDataURL(file);
    } else {
        iconToBeSaved.value = "";
    }
}
function setColor(color) {
    iconToBeSaved.value = color;
}
function selectIcon(icon) {
    let iconArray = icon.split(';');
    iconToShow.value = iconArray[0].slice(4);
    iconColorToShow.value = iconArray[1].slice(6);
    iconToBeSaved.value = icon;
}
function setColorForIcons(color) {
    iconCustomColor.value = color;
    showColorComponentForIcons.value = false;
}

</script>
<template>
    <!-- <AppHeader heading="Teams" breadCrumbs="Edit Issues Task / Issue Types / New Issue Type" /> -->
    <div v-if="!isNewIssueTypeVisible">
        <IssueTypeTabComponent />
    </div>
    <div class="px-3" v-if="isNewIssueTypeVisible">
        <div style="margin-top: 32px !important">

            <div class="mb-4">
                <h5 class="d-none d-md-inline">New Issue Type</h5>
                <div class="d-flex justify-content-between align-item-center">

                    <div class="col-10 col-md-1 p-0">
                        <label for="">Icon</label>
                        <div class="form-group text-center">
                            <div class="iconBorder">
                                <div class="cupicon" @click="showAvatarModal = true">
                                    <CupIcon v-if="iconToBeSaved == null"></CupIcon>
                                    <img id="previewImage" class="image-logo"
                                        style="width: 32px !important; height: 32px !important;"
                                        :src="iconToBeSaved ? iconToBeSaved : './../../../assets/images/profile.png'"
                                        v-else-if="!iconToBeSaved.startsWith('#') && !iconToBeSaved.startsWith('ico')" />
                                    <i v-else-if="iconToBeSaved.startsWith('ico')" class="material-icons fa-icon"
                                        :style="{ color: iconColorToShow }" style="font-size: 1.5rem !important;">
                                        {{ iconToShow }}
                                    </i>
                                </div>
                            </div>
                            <!-- <input type="button" class="input-field"> -->
                        </div>
                    </div>
                    <div class="col-4 p-0 d-none d-md-inline">
                        <label for="">Issue Type Name</label>
                        <input type="text" class="input-field" placeholder="Enter" style="height: 37px;" v-model="name">
                    </div>
                    <div class="col-6 p-0 d-none d-md-inline">
                        <label for="">Workflow</label>
                        <SelectDropdown class="custom-select" v-if="workflows.length > 0"
                            placeholder="'Select Workflow'" displayKey="label" :items="workflows"
                            @onSelectValue="workFlowSelected">
                        </SelectDropdown>
                    </div>
                    <div class="px-0 px-md-3">
                        <label for=""></label>
                        <div class="form-group text-center">
                            <div class="iconBorder" style="padding: 10px 13px !important">
                                <ShareIcon />
                            </div>
                            <!-- <input class="input-field" type="button"> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-4">
                <div class="col-md-3">
                    <h3>Include Sections</h3>

                    <div class="d-flex col mt-4" style="gap: 8px">
                        <CheckBoxInput id="text_cases" v-model="data.text_cases">
                        </CheckBoxInput>
                        <label for="">Test Cases</label>
                    </div>
                    <div class="d-flex col mt-2" style="gap: 8px;">
                        <CheckBoxInput id="peer_review" v-model="data.peer_review">
                        </CheckBoxInput>
                        <label for="">Peer Review</label>
                    </div>

                </div>
            </div>

            <div class="mb-2">
                <div class="d-flex justify-content-between">
                    <h3>Include Fields</h3>
                    <button class="custom-primary-btn" type="button" @click="addNewField">
                        <strong>New Field +</strong>
                    </button>
                </div>
            </div>

            <div class="mb-2">
                <label for="">This will be the results after you drag the items to the box.</label>
            </div>

            <div class="row">
                <div class="col-md-12 fields-drag-container">
                    <Container orientation="horizontal" @drop="onColumnDrop($event)"
                        drag-handle-selector=".column-drag-handle" @drag-start="dragStart">
                        <Draggable v-for="(column, columnIndex) in  scene.children " :key="column.id">
                            <div class="card-container">
                                <div class="card-column-header">
                                    <span class="statusLabel"><b>{{ column.name }}</b> </span>
                                    <span class="issueCounter">
                                        ({{ column.children != undefined ? column.children.length : '0' }})
                                    </span>
                                </div>
                                <Container group-name="col" @drop="(e) => onCardDrop(column.id, e)"
                                    drag-class="card-ghost" :get-child-payload="getCardPayload(column.id)"
                                    drop-class="card-ghost-drop" :drop-placeholder="dropPlaceholderOptions">
                                    <Draggable v-for="(card, cardIndex) in  column.children " :key="card.id">
                                        <div class="card" :id="card.id">
                                            <div class="lineone" style="position: relative;">
                                                <span class="issueNumber">
                                                    {{ card.data.includes('Divider') ? 'Divider Line' : card.data }}
                                                </span>
                                                <span class="showOnNew"
                                                    v-if="column.id == 'columninclude' && card.data != 'Divider' && card.data != 'Time Tracking'">
                                                    <CheckBoxInput :id="index"
                                                        @click="showOnNewScreen(columnIndex, cardIndex)"
                                                        v-model="card.props.showNew">
                                                    </CheckBoxInput>
                                                    <label for="">Show on New Issue screen</label>
                                                </span>
                                                <i v-if="card.props.type == 'default' && !card.data.includes('Divider')"
                                                    class="fa-brands fa-dyalog"
                                                    style="position: absolute; top: 1rem; right: 1rem;"></i>

                                                <i v-if="card.props.type == 'default' && card.data.includes('Divider')"
                                                    class="fa-solid fa-grip-lines"
                                                    style="position: absolute; top: 1rem; right: 1rem;"></i>

                                                <i v-if="card.props.type == 'radio'" class="fa-regular fa-circle-dot"
                                                    style="position: absolute; top: 1rem; right: 1rem;"></i>

                                                <i v-if="card.props.type == 'date'" class="fa-regular fa-calendar"
                                                    style="position: absolute; top: 1rem; right: 1rem;"></i>

                                                <i v-if="card.props.type == 'dropdown'" class="fa-solid fa-caret-down"
                                                    style="position: absolute; top: 1rem; right: 1rem;"></i>

                                                <i v-if="card.props.type == 'text'" class="fa-solid fa-font"
                                                    style="position: absolute; top: 1rem; right: 1rem;"></i>
                                            </div>
                                        </div>
                                    </Draggable>
                                </Container>
                            </div>
                        </Draggable>
                    </Container>
                </div>
            </div>
            <div class="row">
                <div class="d-flex justify-content-center justify-content-md-start mb-4 mt-5">
                    <button type="button" class="custom-secondary-btn me-4 " @click="closeNewIssueType">
                        Close
                    </button>
                    <button class="custom-primary-btn" type="button" @click="saveIssueType">
                        Save
                    </button>
                </div>
            </div>

        </div>
    </div>
    <div v-if="showFieldOptions" class="popup-overlay custom-height-fields">
        <div class="popup" style="width: 80% !important; max-width: 570px;">
            <FieldTypeOptions @close:popup="showFieldOptions = $event" @setting:selected="showFieldOptionsPopup($event)"
                @saved:field="addAdditionalField($event)" />
        </div>
    </div>

    <div v-if="showCreateField" class="popup-overlay custom-height-fields">
        <div class="popup" style="width: 80% !important; max-width: 570px;">
            <CreateField :fieldType="fieldType" @close:popup="showCreateField = $event"
                @setting:selected="fieldType = $event" @saved:field="addAdditionalField($event)" />
        </div>
    </div>

    <div v-if="showAvatarModal" class="popup-overlay">
        <div class="popup" style="width: 80% !important; max-width: 550px;">
            <AvatarModal @convertImage="convertImage" @setColor="setColor" @close="showAvatarModal = false"
                @showColorComponent="showColorComponentForIcons = true" :customIconColor="iconCustomColor"
                @selectedIcon="selectIcon" />
        </div>
    </div>
    <div v-if="showColorComponentForIcons" class="popup-overlay">
        <div class="popup" style="width: 80% !important; max-width: 360px;">
            <ColorComponent @update:showPopup="showColorComponentForIcons = $event"
                @update:colorSelected="setColorForIcons($event)" />
        </div>
    </div>
</template>

<style scoped>
.fields-drag-container .dndrop-container.horizontal {
    width: 100%;
}

.fields-drag-container .dndrop-container.vertical {
    height: 100vh;
    overflow-y: scroll;
}

/* .fields-drag-container .dndrop-container.horizontal .dndrop-draggable-wrapper:first-child .dndrop-container.vertical::after {
    content: '';
    position: absolute;
    border-left: 8px solid #c4c4c4;
    height: 100vh;
    top: 0;
    right: 3.5rem;
    border-radius: 8px;
} */

.fields-drag-container .dndrop-container.horizontal .card i {
    font-size: 20px;
}

.fields-drag-container .dndrop-container.horizontal .card {
    padding: 0.5rem;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    width: 80%;
    margin-bottom: 0.5rem;
    background-color: #FFFFFF;
    min-height: 70px;
}

.fields-drag-container .card .issueNumber {
    color: #000000;
    display: block;
    font-weight: bold;
    font-size: 15px;
}

.fields-drag-container .card .showOnNew div,
.fields-drag-container .card .showOnNew label {
    float: left;
}

.fields-drag-container .card .showOnNew div {
    padding-right: 0.5rem;
}

.fields-drag-container .card .showOnNew label {
    font-size: 12px;
    line-height: 23px;
}

h3 {
    font-size: 24px;
}

i {
    cursor: pointer;
    color: #172b4d;
}

.table h5 {
    margin: 0px;
}

.info-span {
    font-size: 11px;
    display: block;
    text-align: left;
}

.custom {
    background-color: #3D40461A;
    border-radius: 8px;
    padding: 8px 23px;
}

.iconBorder {
    border: 1px solid #c4c4ca;
    border-radius: 8px;
    padding: 6px 13px
}

.cupicon {
    width: 32px;
    height: 32px;
    background: linear-gradient(to bottom, #FFFFFF, #D0D5DD);
    border-radius: 8px;
    display: inline-block;
    vertical-align: middle;
    margin: 0px;
    padding: 5px 0px;
}

.input-field {
    border: 1px solid #c4c4ca;
}

.custom-button {
    border: none;
    color: #000;
    font-weight: normal;
}

.button-seleted {
    background-color: #AAAAAA;
}

@media only screen and (max-width:768px) {
    .tab-wrapper-mobile {
        border-radius: 0;
        box-shadow: none;
        padding: 0 !important;
    }
}
</style>