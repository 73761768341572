<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#FFEAE3" />
    <path
      d="M12 5L6.5 10.5L4 8"
      stroke="#F96332"
      stroke-width="1.6666"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#F96332" />
  </svg>
</template>
