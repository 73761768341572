<script setup>
import AssigneeIcon from '@/components/Icons/AssigneeIcon.vue';
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { onMounted, ref } from "vue";
import api from "../../../api";
import dayjs from 'dayjs';
import AddTimeTrack from '@/components/Pages/Issues/AddTimeTrack.vue';
import Danger from '../../ToastIcons/ErrorIcon.vue';
import DisplayAvatar from '@/components/Pages/Profile/DisplayAvatar.vue';
import AddCircleIconVue from '@/components/Icons/AddCircleIcon.vue';
var router = useRouter();
const toast = useToast();
const timeTrackings = ref([]);
const timeTobeEdited = ref(null);
const emits = defineEmits(['update:parent']);
const timeTrackingProps = defineProps(['globalIssueId']);
const showTimeTrackPopup = ref(false);
let issueIdTemp = router.currentRoute.value.params.id;
const issueIdGlobal = ref(issueIdTemp);
onMounted(() => {
  fetchTimeTrackings();
});
function fetchTimeTrackings() {

  let issueId = timeTrackingProps.globalIssueId;
  if (issueId == null) {
    return;
  }

  api
    .get(`issues/${issueId}/time-trackings`)
    .then((response) => {
      if (response.status == 200) {
        timeTrackings.value = response.data;
        emits('update:parent', timeTrackings.value);
      }
    });
}
function formatDate(dateString) {
  return dayjs(dateString).format('MMMM DD, YYYY - hh:mm a');
}
function editTime(time) {
  timeTobeEdited.value = time;
  showTimeTrackPopup.value = true;
}
function addTime() {
  timeTobeEdited.value = null;
  showTimeTrackPopup.value = true;
}
function deleteTime(time) {
  api
    .delete(`issues/${time.issue_id}/time-trackings/${time.id}`)
    .then((response) => {
      if (response.status == 200) {
        fetchTimeTrackings();
      }
      else {
        toast(response.data['message'], {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      }
    });
}
const timeToBeDeleted = ref(null);
function setTimeToBeDeleted(timeTemp) {
  timeToBeDeleted.value = timeTemp;
}
function onDeleteClickFromTimeModal() {
  let btn = document.getElementById("timeModalCancelBtn");
  btn.click();
  deleteTime(timeToBeDeleted.value);
}

function forceRender() {
  fetchTimeTrackings();
}
</script>
<template>
  <div class="row">
    <div class="col-md-12 mt-4">
      <h5>
        Time Tracking
        <AddCircleIconVue @click="addTime" style="cursor:pointer;" />
      </h5>
    </div>
    <div class="col-md-12">
      <ul class="timeUl">
        <li v-for="(time, index) in timeTrackings" :key="index">
          <DisplayAvatar :avatar="time.creator['avatar']" />
          <span class="username">
            {{ ((time.creator['first_name'] == null && time.creator['last_name'] == null) || (time.creator['first_name']
          == "" && time.creator['last_name'] == "")) ? time.creator['name'] : time.creator['first_name'] + " " +
          (time.creator['last_name'] == null ? "" : time.creator['last_name']) }}
          </span>
          <span class="activity">logged {{ time.spent_time }}</span> -
          <span class="time">{{ formatDate(time.created_at) }}</span>
          <div class="row">
            <div class="col-md-12">
              <a @click="editTime(time)" class="edit">Edit</a>
              <a @click="deleteTime(time)" class="delete">Delete</a>
              <a class="delete" @click="setTimeToBeDeleted(time)" data-bs-toggle="modal"
                data-bs-target="#deleteTimeTrackModal">
                Delete
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div v-if="showTimeTrackPopup">
    <AddTimeTrack @update:showPopup="showTimeTrackPopup = $event" :issueId="issueIdGlobal" lastTimeLogged=""
      :editTime="timeTobeEdited" @created:time="forceRender()" />
  </div>
  <!-- Modal -->
  <div class="modal fade" id="deleteTimeTrackModal" tabindex="-1" aria-labelledby="deleteTimeTrackModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteTimeTrackModalLabel">Alert</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">Are you sure you want to delete this logged time?</div>
        <div class="modal-footer">
          <button type="button" class="custom-secondary-btn" data-bs-dismiss="modal" id="timeModalCancelBtn">
            Cancel
          </button>
          <button type="button" class="custom-primary-btn" @click="onDeleteClickFromTimeModal">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
a.edit,
a.delete {
  cursor: pointer;
}

.timeUl li a {
  font-size: 12px;
  color: #000;
  padding: 0 1rem;
  text-decoration: none;
}

.timeUl li a.delete {
  padding-left: 0;
}

.timeUl li span {
  padding-left: 5px;
}

.timeUl li {
  padding-bottom: 5px;
}

.timeUl {
  list-style: none;
  padding-left: 0;
}

span.username,
span.time {
  font-size: 12px;
  color: #7B8497;
}

span.activity {
  font-size: 12px;
}

h5 {
  font-size: 16px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table tbody tr {
  border: none;
}

.responsive-table td {
  padding: 10px;
}

.headerCard {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  z-index: 1;
}

.setting {
  padding-left: 12px;
}

button:hover {
  background-color: #f96332;
  color: #f4f4f4;
}

.searchBar input {
  border: 1px solid #acacac;
  padding-left: 2.5rem;
}

.flex-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}

.col-3 {
  flex: 0 0 auto;
  width: 20%;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0px !important;
}

.statusHeader {
  background: #EAEAEA;
  margin-top: 20px !important;
  padding: 6px;
}

.issuewrapper {
  border-bottom: 1px solid #EAEAEA;
  border-top: none;
}

.statusHeader span {
  padding-right: 5px;
  ;
}
</style>
