import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import VideoNodeComponent from './VideoNodeComponent.vue';
export default Node.create({
    name: 'videoView',

    group: 'block',

    atom: true,

    addAttributes() {
        return {
            source: {
                default: '',
            },
            filePath: {
                default: '',
            },
            type: {
                default: '',
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'video-view',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['video-view', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(VideoNodeComponent)

    },
})