<script setup>
import AppHeader from "@/components/AppHeader.vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import DisplayIcon from '@/components/Icons/DisplayIcon.vue';
import DisplayAvatar from "../Profile/DisplayAvatar.vue";
import DeleteIcon from "../../ToastIcons/DeleteIcon";
import SearchInputIcon from "@/components/Icons/SearchInputIcon.vue";
import DownArrowIcon from '@/components/Icons/DownArrowIcon.vue';
import OnlyMeIcon from "@/components/Icons/OnlyMeIcon.vue";
import EveryOneIcon from "@/components/Icons/EveryOneIcon.vue";
import TeamPeopleIcon from "@/components/Icons/TeamPeopleIcon.vue";
import ProjectPeopleIcon from "@/components/Icons/ProjectPeopleIcon.vue";
import FavIcon from "@/components/Icons/FavIcon.vue";
import NewView from "@/components/Pages/Views/NewView.vue";
import { onMounted, ref, inject } from "vue";
import api from "../../../api";

var router = useRouter();
const data = ref([]);
const selectedTeam = ref(null);
const toast = useToast();
const leader = ref(null);
const createNewView = ref(false);
const views = ref([]);
const viewFilter = ref('all');
const newFilterSharing = ref('onlyme');
const sharing = ref([
  { 'value': 'onlyme', 'label': 'Only Me' },
  { 'value': 'everyone', 'label': 'Every One' },
  { 'value': 'project', 'label': 'Project' },
  { 'value': 'team', 'label': 'Team' }
]);
onMounted(() => {
  fetchViews();
});


function fetchViews() {
  views.value = [];
  api.get(`view`).then((response) => {
    if (response.status == 200) {
      let temp = response.data;

      for (let view of temp) {
        for (let type of sharing.value) {
          if (type.value == view.sharing) {
            if (views.value[type.value] == undefined) {
              views.value[type.value] = [];
            }
            views.value[type.value].push(view);
          }
        }
      }
    }
  });
}

function onDeleteClick(item) {
  selectedTeam.value = item;
}

function refreshView() {
  createNewView.value = false;
  fetchViews();
}

function onDeleteClickFromModal() {
  api.delete(`teams/${selectedTeam.value.id}`).then((response) => {
    let btn = document.getElementById("teamsModalCancelBtn");
    btn.click();

    //fetchData();
    toast("Your team has been deleted.", {
      position: "top-right",
      timeout: 4025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: DeleteIcon,
      rtl: false,
    });
  });
}
function selectLeader(teamId, leaderId) {
  leader.value = leader.value === leaderId ? null : leaderId;
}

const workspaceSlug = ref("temp");
let workSpaceTemp = JSON.parse(localStorage.getItem("workspace"));
workspaceSlug.value = workSpaceTemp.slug;
const showFilterBox = inject('showFilterBox');
const filterQuery = inject('filterQuery');
function goToIssuesScreen(filters) {
  filterQuery.value = filters;
  showFilterBox.value = true;
  router.push(`/${workspaceSlug.value}/issues`);
}
const showNewView = inject('showNewView');
function gotoNewViewScreen() {
  showNewView.value = true;
  router.push(`/${workspaceSlug.value}/issues`);
}
</script>
<template>
  <div class="viewWrapper" v-if="createNewView == false">
    <div class="wrap-header d-flex justify-content-between border-bottom align-items-center">
      <AppHeader heading="Views" breadCrumbs="Issue Views" :showBorder="false"></AppHeader>
      <div class="d-md-none d-flex">

        <BDropdown style="float: right; margin-right: 8px;" class="display-button" variant="link"
          toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <DisplayIcon />
            <DownArrowIcon />
          </template>
          <span style="font-size: 12px !important; font-weight: 400;">Show Columns</span>
          <br />
          <span class="showColumnButton">Sharing</span>
          <span class="showColumnButton selected">Name</span>
          <span class="showColumnButton">Workspace</span><br />
          <span class="showColumnButton">Updated</span>
          <span class="showColumnButton">Created</span>
          <span class="showColumnButton">Owner</span><br />
          <span class="resetDefaut">Reset to Default</span> <span class="resetAsDefault">Set as Default</span>
        </BDropdown>

      </div>
    </div>
    <div class="px-3">
      <div class="py-4">
        <div class="d-flex flex-md-nowrap flex-wrap justify-content-between">
          <div class="col-12 col-md-4">
            <div class="align-items-center component2 d-flex justify-content-around justify-content-md-start">
              <div class="filterHeading" @click="viewFilter = 'all'" :class="{
    active: viewFilter == 'all'
  }">All</div>
              <div class="filterHeading" @click="viewFilter = 'onlyme'" :class="{
    active: viewFilter == 'onlyme'
  }">Only me</div>
              <div class="filterHeading" @click="viewFilter = 'everyone'" :class="{
    active: viewFilter == 'everyone'
  }">Everyone</div>
              <div class="filterHeading" @click="viewFilter = 'team'" :class="{
    active: viewFilter == 'team'
  }">Teams</div>
              <div class="filterHeading" @click="viewFilter = 'project'" :class="{
    active: viewFilter == 'project'
  }">Projects</div>
            </div>
          </div>
          <div class="d-flex flex-row-reverse mt-3 mt-md-0 w-100 justify-content-between justify-content-md-start">
            <button class="custom-primary-btn" type="button" style="float: right;" @click="gotoNewViewScreen">
              <strong>New View</strong>
            </button>
            <BDropdown style="float: right; margin-right: 8px;" class="display-button d-none d-md-inline" variant="link"
              toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <DisplayIcon />
                <span style="padding-left: 10px; padding-right: 10px;">Display</span>
                <DownArrowIcon />
              </template>
              <span style="font-size: 12px !important; font-weight: 400;">Show Columns</span>
              <br />
              <span class="showColumnButton">Sharing</span>
              <span class="showColumnButton selected">Name</span>
              <span class="showColumnButton">Workspace</span><br />
              <span class="showColumnButton">Updated</span>
              <span class="showColumnButton">Created</span>
              <span class="showColumnButton">Owner</span><br />
              <span class="resetDefaut">Reset to Default</span> <span class="resetAsDefault">Set as Default</span>
            </BDropdown>
            <div class="searchFieldContainer d-flex">
              <SearchInputIcon />
              <input type="text" name="search" placeholder="Search for a view" />
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="">
          <div class="mobile-table-display d-md-none d-block">
            <div class="tab-body" v-for="(item, index) in sharing" :key="index">
              <div colspan="11">
                <div v-if="viewFilter == 'all' || viewFilter == item.value">
                  <div class="row-view tab-wrapper p-3 mb-3" v-for="(view, viewIndex) in views[item.value]"
                    :key="viewIndex">
                    <div class="d-flex">
                      <div class="me-1">
                        <OnlyMeIcon v-if="view.sharing == 'onlyme'" />
                        <EveryOneIcon v-if="view.sharing == 'everyone'" />
                        <ProjectPeopleIcon v-if="view.sharing == 'project'" />
                        <TeamPeopleIcon v-if="view.sharing == 'team'" />
                      </div>
                      <div class="me-1 w-100">
                        <a @click="goToIssuesScreen(view.filters)" class="viewRuterLink">
                          <span class="heading" style="cursor: pointer;">{{ view.title }}</span>
                        </a>
                        <span class="description">{{ view.description }}</span>
                      </div>
                      <div class="me-3">
                        <FavIcon />
                      </div>
                      <div class="cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i style="font-size: 1.5rem;" class="fa-solid fa-ellipsis"></i>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li class="text-darkgray">
                            <a class="dropdown-item fs-14px" @click="onEditClick(item)">Edit</a>
                          </li>
                          <li class="text-darkgray">
                            <a class="dropdown-item fs-14px" @click="onEditClick(item)">Clone</a>
                          </li>
                          <li class="text-darkgray">
                            <a class="dropdown-item fs-14px" @click="onEditClick(item)">Change Owner</a>
                          </li>
                          <li class="text-darkgray">
                            <a class="dropdown-item fs-14px" @click="onEditClick(item)">Change Sharing</a>
                          </li>
                          <li class="text-darkgray">
                            <a class="dropdown-item fs-14px" @click="onEditClick(item)">Copy Link View</a>
                          </li>
                          <li class="text-darkgray">
                            <a class="dropdown-item fs-14px" @click="onEditClick(item)">Delete</a>
                          </li>
                        </ul>
                      </div>
                    </div>



                    <div class="text-container mt-3">
                      <div class="d-flex justify-content-between mb-2">
                        <span class="text-secondary">Workspace</span>
                        <span>
                          Campbell
                        </span>
                      </div>

                      <div class="d-flex justify-content-between mb-2">
                        <span class="text-secondary">Updated</span>
                        <span>
                          21/7/2023
                        </span>
                      </div>

                      <div class="d-flex justify-content-between mb-2">
                        <span class="text-secondary">Created</span>
                        <span>
                          17/4/2023
                        </span>
                      </div>



                      <div class="d-flex justify-content-between mb-2">
                        <span class="text-secondary">Owner</span>
                        <span>
                          <DisplayAvatar
                            :avatar="(view.createdBy != null && view.createdBy['avatar'] != null) ? view.createdBy['avatar'] : null" />
                          <span style="padding-left: 7px; color: #333333;">
                            {{ (view.createdBy == null) ? '' : ((view.createdBy['first_name'] == '' &&
    view.createdBy['last_name']
    ==
    '')
    || (view.createdBy['first_name'] == null && view.createdBy['last_name'] == null)) ?
    view.createdBy['name'] : view.createdBy['first_name'] + ' ' + view.createdBy['last_name'] }}
                          </span>
                        </span>



                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>

          </div>


          <table class="table table-borderless align-middle d-none d-md-table">
            <thead class="thead-view">
              <tr class="head-row-view align-middle">
                <th colspan="6" scope="col">Name & Description</th>
                <th colspan="4" scope="col">Owner</th>
                <th colspan="1" scope="col" style="text-align: right;">Actions</th>
              </tr>
            </thead>
            <tbody class="tab-body" v-for="(item, index) in sharing" :key="index">
              <tr class="row-view" v-if="viewFilter == 'all' || viewFilter == item.value">
                <td colspan="11" style="background-color: #eaeaea; border-radius: 6px;">
                  <span style="margin-right: 10px;">
                    <OnlyMeIcon v-if="item.value == 'onlyme'" />
                    <EveryOneIcon v-if="item.value == 'everyone'" />
                    <ProjectPeopleIcon v-if="item.value == 'project'" />
                    <TeamPeopleIcon v-if="item.value == 'team'" />
                  </span>
                  <span style="font-weight: 500;">{{ item.label }}</span>
                </td>
              </tr>
              <tr>
                <td colspan="11">
                  <table style="width: 100%;">
                    <tbody v-if="viewFilter == 'all' || viewFilter == item.value">
                      <tr class="row-view" v-for="(view, viewIndex) in views[item.value]" :key="viewIndex">
                        <td style="width: 40%;">
                          <div class="td-float me-1">
                            <OnlyMeIcon v-if="view.sharing == 'onlyme'" />
                            <EveryOneIcon v-if="view.sharing == 'everyone'" />
                            <ProjectPeopleIcon v-if="view.sharing == 'project'" />
                            <TeamPeopleIcon v-if="view.sharing == 'team'" />
                          </div>
                          <div class="td-float me-1">
                            <a @click="goToIssuesScreen(view.filters)" class="viewRuterLink">
                              <span class="heading" style="cursor: pointer;">{{ view.title }}</span>
                            </a>
                            <span class="description">{{ view.description }}</span>
                          </div>
                          <div class="td-float me-1">
                            <FavIcon />
                          </div>
                        </td>
                        <td style="text-align: left; width: 35%;">
                          <DisplayAvatar
                            :avatar="(view.createdBy != null && view.createdBy['avatar'] != null) ? view.createdBy['avatar'] : null" />
                          <span style="padding-left: 7px; color: #333333;">
                            {{ (view.createdBy == null) ? '' : ((view.createdBy['first_name'] == '' &&
    view.createdBy['last_name']
    ==
    '')
    || (view.createdBy['first_name'] == null && view.createdBy['last_name'] == null)) ?
    view.createdBy['name'] : view.createdBy['first_name'] + ' ' + view.createdBy['last_name'] }}
                          </span>
                        </td>
                        <td>
                          <div class="cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i style="font-size: 1.5rem;" class="fa-solid fa-ellipsis"></i>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li class="text-darkgray">
                                <a class="dropdown-item fs-14px" @click="onEditClick(item)">Edit</a>
                              </li>
                              <li class="text-darkgray">
                                <a class="dropdown-item fs-14px" @click="onEditClick(item)">Clone</a>
                              </li>
                              <li class="text-darkgray">
                                <a class="dropdown-item fs-14px" @click="onEditClick(item)">Change Owner</a>
                              </li>
                              <li class="text-darkgray">
                                <a class="dropdown-item fs-14px" @click="onEditClick(item)">Change Sharing</a>
                              </li>
                              <li class="text-darkgray">
                                <a class="dropdown-item fs-14px" @click="onEditClick(item)">Copy Link View</a>
                              </li>
                              <li class="text-darkgray">
                                <a class="dropdown-item fs-14px" @click="onEditClick(item)">Delete</a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="newViewWrapper" v-if="createNewView">
    <NewView @aftersave="refreshView" />
  </div>

  <!-- Modal -->
</template>
<style lang="scss">
.viewRuterLink {
  text-decoration: none;
}

.modalWrapper {
  padding: 15px;
}

.filterHeading {
  font-size: 12px !important;
  font-weight: 500;
  cursor: pointer;
}

.tab-wrapper .thead-view .head-row-view th {
  background-color: #FFF !important;
}

span.heading {
  color: #3D4046;
  font-weight: 500;
  display: block;
  width: 100%;
  text-align: left;
}

span.description {
  font-size: 12px !important;
}

.td-float {
  float: left;
}

.sharebutton {
  color: #3D4046;
  padding: 15px 10px;
  line-height: 20px;

}

.topHeader {
  width: 100%;
}

.leftWrapper {
  float: left;
  width: 50%;
}

.fieldWrapper {
  float: left;
  margin-left: 10px;

}

.fieldWrapper .viewname,
.fieldWrapper .viewdescription {
  border: none;
}

.fieldWrapper .viewname:focus,
.fieldWrapper .viewdescription:focus {
  outline: none;
  outline-width: 0;
}

.fieldWrapper .viewname {
  color: #7B8497;
  font-size: 18px;
  font-weight: 500;
}

.fieldWrapper .viewdescription {
  font-size: 14px;
  color: #7B8497;

}

.newVewIcon {
  width: 54px;
  height: 52px;
  padding: 15px;
  border-radius: 8px;
  gap: 10px;
  background: linear-gradient(0deg, #EAEAEA, #EAEAEA),
    linear-gradient(0deg, #C4C4CA, #C4C4CA);
  border: 1px solid #C4C4CA;
  float: left;
}

.rightWrapper {
  float: left;
  width: 50%;
}

.rightWrapper button {
  float: right;
  margin-right: 15px;
}

.searchFieldContainer {
  margin-right: 8px;
  float: right;
  width: 224px;
  height: 40px;
  border-radius: 8px;
  background: #EAEAEA;
  padding: 8px 10px;
}

.searchFieldContainer input[type="text"] {
  border: none;
  background: none;
  color: #7B8497;
  margin-left: 5px;
}

.searchFieldContainer input[type="text"]:focus {
  border: none;
  outline-width: none;
  outline: none;

}

.resetDefaut {
  font-size: 12px !important;
  font-weight: 500;
  color: #F96332;
  float: left;
  margin-top: 12px;
  cursor: pointer;
}

.resetAsDefault {
  font-size: 12px !important;
  font-weight: 500;
  color: #3D4046;
  float: right;
  margin-top: 12px;
  cursor: pointer;
}

.showColumnButton.selected {
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  color: #7B8497;
  font-size: 12px !important;

}

.showColumnButton {
  width: 57px;
  height: 20px;
  padding: 3px 8px 3px 8px;
  border-radius: 4px;
  gap: 10px;
  background: #EAEAEA;
  font-size: 12px !important;
  color: #3D4046;
  margin-right: 5px;
  cursor: pointer;
}

.display-button button {
  background: linear-gradient(0deg, #EAEAEA, #EAEAEA),
    linear-gradient(0deg, #C4C4CA, #C4C4CA);
  border: 1px solid #C4C4CA;
  color: #3D4046;
  font-weight: 500;
  font-size: 14px;
}

.selected-leader {
  background: #EAEAEA;
}

.tab-wrapper {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(249, 99, 50, 0.1);

  .table-responsive {
    overflow-y: auto;
    height: 80vh;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .thead-view {
    .head-row-view {
      height: 60px;

      th {
        background-color: #eaeaea;
        font-weight: 500;
        position: sticky;
        top: 0px;
      }

      th:first-child {
        border-radius: 8px 0 0 0;
      }

      th:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  .tab-body {
    .row-view {
      height: 60px;
    }
  }
}

.modal-dialog {
  top: 30%
}
</style>
