<script setup>
import { onMounted } from 'vue';
import { ref, inject } from 'vue'
import InputField from '../Fields/InputField.vue';
import LayerIcon from "@/components/Icons/LayerIcon.vue";
import SearchIcon from "@/components/Icons/SearchIcon.vue";
import api from '@/api';
const emits = defineEmits(['update:showPopup']);
const filterText = ref("");
const showSearch = ref(false);
const viewFilter = ref("all");
const issues = ref([]);
const documents = ref([]);
const projects = ref([]);
const recentSearches = ref([]);
const workspace = ref({});
const baseUrl = ref("");
onMounted(() => {
  recentSearches.value = JSON.parse(localStorage.getItem('recentSearches'));
  workspace.value = JSON.parse(localStorage.getItem('workspace'));
  baseUrl.value = window.location.origin;
});
function closePopup() {
  emits('update:showPopup', false);
}
function showSearchResults() {
  if (filterText.value != "") {
    showSearch.value = true;
    if (recentSearches.value == undefined || recentSearches.value == null) {
      recentSearches.value = [];
      recentSearches.value.push(filterText.value);
      localStorage.setItem('recentSearches', JSON.stringify(recentSearches.value));
    }
    else {
      recentSearches.value.push(filterText.value);
      localStorage.setItem('recentSearches', JSON.stringify(recentSearches.value));
    }
    api.get(`search?search=${filterText.value}`).then(function (response) {
      if (response.status == 200) {
        let searchResults = response.data;
        issues.value = searchResults['issues'];
        documents.value = searchResults['document'];
        projects.value = searchResults['projects'];
      }
    });
  }
  else {
    showSearch.value = false;
  }
}

function clearRecentSearches() {
  localStorage.setItem('recentSearches', null);
  recentSearches.value = [];
}

function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) <= 0 ? '1 seconds' : Math.floor(seconds) + " seconds";
}

</script>

<template>
  <div class="popup-overlay">
    <div class="popup">
      <div class="d-flex align-items-center pt-3 mb-4">
        <h2>
          Search
        </h2>
        <i class="fa fa-close custom-fa-close" @click="closePopup"></i>
      </div>
      <div class="row">
        <div class="col-md-12 searchField">
          <span>
            <SearchIcon class="big" />
          </span>
          <input placeholder="Search" class="custom-field" @keyup.enter="showSearchResults" :svg-icon="true" type="text"
            v-model="filterText" />
        </div>
      </div>
      <div class="recentWrappers" v-if="showSearch == false">
        <div class="row">
          <div class="col-md-6 mt-5">
            <div class="row">
              <div class="col-md-12">
                <span class="recentSearchHeading">Recent Search</span><br><br>
                <div v-for="(recent, index) in recentSearches" :key="index">
                  <span>
                    <SearchIcon />
                  </span>
                  <span style="padding-left: 10px;">
                    {{ recent }}
                  </span><br><br>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span class="recentSearchHeading">Recent Issues</span><br><br>
                <span>
                  <SearchIcon />
                </span>
                <span style="padding-left: 10px;">
                  <LayerIcon />
                </span>
                <span>
                  MS-163
                </span><br><br>
              </div>
            </div>
          </div>
          <div class="col-md-6 text-right mt-5">
            <button class="btn btn-secondary custom-secondary" @click="clearRecentSearches">Clear</button>
          </div>
        </div>

      </div>
      <div class="searchWrapper" v-if="showSearch">
        <div class="row">
          <div class="col-md-6 mt-4">
            <div class="row w-30">
              <div class="d-flex align-items-center component2" style="width: 75%;">
                <div class="filterHeading" @click="viewFilter = 'all'" :class="{
                  active: viewFilter == 'all'
                }">All</div>
                <div class="filterHeading" @click="viewFilter = 'issues'" :class="{
                  active: viewFilter == 'issues'
                }">Issues ({{ issues.length }})</div>
                <div class="filterHeading" @click="viewFilter = 'documents'" :class="{
                  active: viewFilter == 'documents'
                }">Documents ({{ documents.length }})</div>
                <div class="filterHeading" @click="viewFilter = 'projects'" :class="{
                  active: viewFilter == 'projects'
                }">Projects ({{ projects.length }})</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-3">
                <div class="issuesResults mb-2" v-if="viewFilter == 'all' || viewFilter == 'issues'">
                  <span class="searchHeading mb-1">Issues</span>
                  <div v-for="(result, index) in issues" :key="index" class="mb-2">
                    <span style="padding-right: 10px;">
                      <LayerIcon />
                    </span>
                    <span style="padding-right: 10px;">
                      {{ result.project.slug }}-{{ result.issue_number }}
                    </span>
                    <span style="padding-right: 10px; font-weight: 500;">
                      <a target="_blank" style="color: #2A2C32; text-decoration: none;"
                        :href="baseUrl + '/' + workspace.slug + '/issue/' + result.project.slug + '-' + result.issue_number">
                        {{ result.title }}
                      </a>
                    </span><br>
                    <span style="color: #7B8497; font-size: 12px !important; font-weight: 500;  padding-left: 25px;">
                      {{ result.issueType.name }}
                    </span>
                    <span style="color: #7B8497; font-size: 12px !important; font-weight: 400; float: right;">
                      Updated {{ timeSince(new Date(result.updatedAt)) }} ago
                    </span>
                  </div>

                </div>
                <div class="documentResults mb-2" v-if="viewFilter == 'all' || viewFilter == 'documents'">
                  <span class="searchHeading mb-1">Documents</span>
                  <div v-for="(result, index) in documents" :key="index" class="mb-2">
                    <span style="padding-right: 10px;">
                      <LayerIcon />
                    </span>
                    <span style="padding-right: 10px; font-weight: 500;">
                      {{ result.name }}
                    </span><br>
                    <span style="color: #7B8497; font-size: 12px !important; font-weight: 400; float: right;">
                      Uploaded {{ timeSince(new Date(result.createdAt)) }} ago
                    </span>
                  </div>
                </div>
                <div class="projectResults mb-2" v-if="viewFilter == 'all' || viewFilter == 'projects'">
                  <span class="searchHeading mb-1">Projects</span>
                  <div v-for="(result, index) in projects" :key="index" class="mb-2">
                    <span style="padding-right: 10px;">
                      <LayerIcon />
                    </span>
                    <span style="padding-right: 10px; font-weight: 500;">
                      {{ result.name }}
                    </span><br>
                    <span style="color: #7B8497; font-size: 12px !important; font-weight: 400; float: right;">
                      Updated {{ timeSince(new Date(result.updatedAt)) }} ago
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.searchResultsUl {
  list-style: none;
  list-style-position: outside;
}

.popup {
  height: 80vh;
}

.searchHeading {
  color: #2A2C32;
}

.recentSearchHeading {
  font-size: 16px !important;
  color: #3D4046;

}

.text-right {
  text-align: right;
}

.custom-secondary {
  background: linear-gradient(0deg, #EAEAEA, #EAEAEA),
    linear-gradient(0deg, #C4C4CA, #C4C4CA);
  border: 1px solid #C4C4CA;
  color: #3D4046;
  font-size: 12px;
}

.big {
  width: 24px;
  height: 24px;
}

.searchField {
  background: #EAEAEA !important;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  line-height: 44px;
}

.custom-field {
  background: none;
  border: none;
  box-shadow: none;
  width: 80%;
  padding-left: 1rem;
  outline: 0;
}

.custom-field:focus {
  outline: 0;
}

.popup {
  width: 70% !important;
}

.fa-close {
  position: absolute;
  right: 3rem;
  top: 2rem;
  background: #999;
  color: #FFF;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}
</style>