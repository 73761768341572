<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M27.3134 27.3136C33.5617 21.0653 33.5617 10.9347 27.3134 4.6864C21.0651 -1.56193 10.9345 -1.56193 4.68622 4.6864C-1.56211 10.9347 -1.56211 21.0653 4.68622 27.3136C10.9345 33.5619 21.0651 33.5619 27.3134 27.3136Z"
      fill="#00B84D"
    />
    <path
      d="M12.3605 23.4914C12.5177 23.6486 12.7311 23.737 12.9534 23.737C13.1758 23.737 13.3892 23.6486 13.5463 23.4914L24.9728 12.0649C25.13 11.9078 25.2184 11.6944 25.2184 11.472C25.2184 11.2497 25.13 11.0363 24.9728 10.8791L22.6022 8.50847C22.2745 8.18077 21.7441 8.18077 21.4173 8.50847L12.9543 16.9714L10.5837 14.6008C10.4265 14.4436 10.2131 14.3552 9.99076 14.3552C9.76843 14.3552 9.55503 14.4436 9.39787 14.6008L7.02721 16.9714C6.69951 17.2991 6.69951 17.8295 7.02721 18.1563L12.3605 23.4914Z"
      fill="white"
    />
  </svg>
</template>
