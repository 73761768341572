<script setup>
import LinkIcon from "@/components/Icons/LinkIcon.vue";
import ListIcon from "@/components/Icons/ListIcon.vue";
import KanbanIcon from "@/components/Icons/KanbanIcon.vue";
import TimelineIcon from "@/components/Icons/TimelineIcon.vue";

import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { onMounted, ref, inject, watch } from "vue";
import api from "../../../api";

import NewIssue from "@/components/Pages/Issues/NewIssue.vue";
import { getCurrentInstance } from "vue";
import KanbanTemplate from "@/components/Pages/Issues/KanbanTemplate.vue";
import Dropdown from "@/components/FilterComponents/Dropdown.vue";
import Display from "@/components/FilterComponents/Display.vue";
import LabelsDropdown from "@/components/Pages/General/LabelsDropdown.vue";
import SubscribersDropdown from "@/components/Pages/General/SubscriberDropdown.vue";
import NewViewIcon from "@/components/Icons/NewViewIcon.vue";
import ShareIconBlack from "@/components/Icons/ShareIconBlack.vue";
import ShareIcon from "@/components/Icons/ShareIcon.vue";
import Danger from "../../ToastIcons/ErrorIcon.vue";
import Success from "../../ToastIcons/SuccessIcon.vue";
import ListTemplate from "@/components/Pages/Issues/ListTemplate.vue";
import {
  db,
  defaultDatabase,
  initiateDatabase,
} from "@/localdatabase/dbConfig";
import DisplayDropdown from "../General/DisplayDropdown.vue";

var router = useRouter();
const showPopup = ref(false);
const data = ref([]);
const toast = useToast();
const renderer = ref(0);
const reloadIssues = inject("reloadIssues");
const issuesFiltered = inject("issuesFiltered");
const filterTypes = inject("filterTypes");
const grouping = ref('status');
const ordering = ref('newest');
const completion = ref('all');

const cardSettings = ref({
  contentField: "title",
  headerField: "issueNumber",
});
const reloadChildView = ref(false);
const statuses = ref([]);
const priority = inject('priority');

const labels = ref([
  {
    label: "Milestone1",
    id: "1",
    child: [{ label: "M1.1", id: "6", color: "#000" }],
    color: "#ccc",
  },
  { label: "Milestone2", id: "2", child: null, color: "#000" },
  { label: "Milestone3", id: "3", child: null, color: "#FF0" },
  { label: "Milestone4", id: "4", child: null, color: "#F00" },
  { label: "Milestone5", id: "5", child: null, color: "#C40C56" },
]);

const issues = ref({});
const labelsToDisplay = ref([]);
const workspaceSlug = ref("temp");
const app = getCurrentInstance();
onMounted(() => {
  workspaceSlug.value = JSON.parse(localStorage.getItem('workspace'));
  getIssuesForGrouping();
});

function getIssuesForGrouping() {
  if (grouping.value == "status") {
    fetchWorkflowStatuses();
  }
}

const sameStatuses = ref([]);
function fetchWorkflowStatuses() {
  sameStatuses.value = [];
  statuses.value = [];
  db.workflow_statuses.toArray().then((localDbStatuses) => {
    for (let sta of localDbStatuses) {
      if (!sameStatuses.value.includes(sta.name)) {
        statuses.value.push({
          id: sta.id,
          open: true,
          label: sta.name,
          value: sta.label,
          color: sta.color,
          icon: ''
        });
        sameStatuses.value.push(sta.name);
      }
    }
    getIssues();
  })
    .catch((error) => {
      if (error) {
        console.log(error);
        return false;
      }
    });
}

watch(reloadIssues, (newValue, oldValue) => {
  if (newValue == true) {
    reloadIssues.value = false;
    fetchWorkflowStatuses();
  }
});

function getIssues() {
  // Retrieve the teamId from the query parameters
  const urlParams = new URLSearchParams(window.location.search);
  const teamId = urlParams.get('teamId');
  console.log(teamId, "teamId");
  // Determine the query based on the presence of teamId
  let query;
  if (teamId) {
    query = db.issues.where({ team_id: parseInt(teamId) });
  } else {
    query = db.issues;
  }

  // Fetch issues based on the query
  query.toArray().then((issuesCollection) => {
    if (issuesCollection.length > 0) {
      let tempIssues = issuesCollection;
      let organizedIssues = [];
      for (let issue of tempIssues) {
        let issueToBeContinued = false;

        if (filterQuery.value != null) {
          for (let [key, value] of Object.entries(filterQuery.value)) {
            if (value.operator == "is") {
              if (!value.filterValues.includes(issue[key])) {
                issueToBeContinued = true;
                break;
              }
            } else {
              if (value.filterValues.includes(issue[key])) {
                issueToBeContinued = true;
                break;
              }
            }
          }
        }
        if (issueToBeContinued) {
          continue;
        }

        if (issue["issuestatus"] != null) {
          for (let status of statuses.value) {
            if (issue["issuestatus"]["name"] == status["label"]) {
              if (organizedIssues["status" + status["label"]] == undefined) {
                organizedIssues["status" + status["label"]] = [];
              }
              let issuelabels = issue["labels"] != null ? issue["labels"].split(",") : [];
              issue["labelsArray"] = [];
              for (let label of labels.value) {
                if (issuelabels.includes(label.id)) {
                  if (issue["labelsArray"] == undefined) {
                    issue["labelsArray"] = [];
                  }
                  label["colorArray"] = hexToRgb(label.color);
                  issue["labelsArray"].push(label);
                }
                if (label.child != null) {
                  for (let childLabel of label.child) {
                    if (issuelabels.includes(childLabel.id)) {
                      if (issue["labelsArray"] == undefined) {
                        issue["labelsArray"] = [];
                      }
                      childLabel["colorArray"] = hexToRgb(childLabel.color);
                      issue["labelsArray"].push(childLabel);
                    }
                  }
                }
              }

              for (let prio of priority.value) {
                if (prio.value == issue["priority"]) {
                  issue["priority"] = prio;
                  break;
                }
              }
              issue["openStatus"] = false;
              issue["openPriority"] = false;
              issue["openLabels"] = false;
              issue["openAssignee"] = false;
              issue["created_at"] = formatDate(issue["created_at"]);
              issue["actualStatus"] = issue["status"]["value"];
              issue["issueNumber"] = issue["identifier"];
              issue["payload"] = { issueId: issue["id"] };
              organizedIssues["status" + status["label"]].push(issue);
              break;
            }
          }
        } else {
          if (organizedIssues[grouping.value + "No Status"] == undefined) {
            organizedIssues[grouping.value + "No Status"] = [];
          }
          let issuelabels = issue["labels"] != null ? issue["labels"].split(",") : [];
          issue["labelsArray"] = [];
          for (let label of labels.value) {
            if (issuelabels.includes(label.id)) {
              if (issue["labelsArray"] == undefined) {
                issue["labelsArray"] = [];
              }
              label["colorArray"] = hexToRgb(label.color);
              issue["labelsArray"].push(label);
            }
            if (label.child != null) {
              for (let childLabel of label.child) {
                if (issuelabels.includes(childLabel.id)) {
                  if (issue["labelsArray"] == undefined) {
                    issue["labelsArray"] = [];
                  }
                  childLabel["colorArray"] = hexToRgb(childLabel.color);
                  issue["labelsArray"].push(childLabel);
                }
              }
            }
          }

          for (let prio of priority.value) {
            if (prio.value == issue["priority"]) {
              issue["priority"] = prio;
              break;
            }
          }
          issue["openStatus"] = false;
          issue["openPriority"] = false;
          issue["openLabels"] = false;
          issue["openAssignee"] = false;
          issue["created_at"] = formatDate(issue["created_at"]);
          issue["actualStatus"] = "";
          issue["issueNumber"] = issue["identifier"];
          issue["payload"] = { issueId: issue["id"] };
          organizedIssues["status" + "No Status"].push(issue);
        }
      }
      issues.value = organizedIssues;
      issuesFiltered.value = !issuesFiltered.value;
    }
  }).catch((error) => {
    console.log(error);
  });
}





function toggleAssigneeDropdown(issueTemp) {
  localStorage.setItem("teamId", issueTemp["team_id"]);
  issueTemp["openAssignee"] = !issueTemp["openAssignee"];
}

function toggleStatusDropdown(issueTemp) {
  issueTemp["openStatus"] = !issueTemp["openStatus"];
}

function togglePriorityDropdown(issueTemp) {
  issueTemp["openPriority"] = !issueTemp["openPriority"];
}

function toggleLabelDropdown(issueTemp) {
  issueTemp["openLabels"] = !issueTemp["openLabels"];
}

function editedTaskPriority(issueTemp, prio) {
  issueTemp["priority"] = prio;
  updateTask({ priority: prio.value }, issueTemp["id"]);
}
function editedTaskStatus(issueTemp, sta) {
  issueTemp["status"] = sta.id;
  issueTemp["issuestatus"] = sta;
  updateTask(
    {
      status: sta.id,
      issuestatus: {
        id: sta.id,
        workflow_id: sta.workflow_id,
        label: sta.value,
        name: sta.label,
        description: sta.description,
        is_default: sta.is_default,
        color: sta.color,
      },
    },
    issueTemp["id"]
  );
}
function editedTaskAssignee(issueTemp, mem) {
  updateTaskAssignee({ assignee: mem.id, assigneeUser: mem }, issueTemp["id"]);
}
const listType = ref("list");
function changeListView(type) {
  listType.value = type;
  localStorage.setItem("listType", listType.value);
}

function updateTaskAssignee(data, id) {
  // First Update Local Database and then send the API request
  let tempData = {
    assignee: data.assignee,
    assigneeUser: {
      id: data.assigneeUser["id"],
      name: data.assigneeUser["name"],
      username: data.assigneeUser["username"],
      first_name: data.assigneeUser["first_name"],
      last_name: data.assigneeUser["last_name"],
      avatar: data.assigneeUser["avatar"],
      is_invited: data.assigneeUser["is_invited"],
      invited_by: data.assigneeUser["invited_by"],
      email: data.assigneeUser["email"],
    },
  };
  db.issues.update(id, tempData).then((response) => {
    getIssues();
    api
      .put(`issues/${id}`, tempData);
  });
  // First Update Local Database and then send the API request
}

function updateTask(data, id) {
  // First Update Local Database and then send the API request
  db.issues.update(id, data).then((updated) => {
    if (updated) {
      console.log("Updated " + id);
      getIssues();
      api
        .put(`issues/${id}`, data);
    }
    else {
      console.log("NOT Updated");
    }
  });
  // First Update Local Database and then send the API request
}

function formatDate(dateString) {
  let createdAt = new Date(dateString);
  const optionsDate = {
    month: "short",
    day: "numeric",
  };
  return createdAt.toLocaleDateString("en-US", optionsDate);
}

const hexToRgb = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));


const selectedStatusForNewIssue = ref("");
function showNewIssueWithStatus(index) {
  selectedStatusForNewIssue.value = statuses.value[index];
  showPopup.value = true;
}

const showFilterBox = inject('showFilterBox');
const filterQuery = inject('filterQuery');
const showAssigneeForBulkAction = ref(false);
const showLabelsForBulkAction = ref(false);

async function updateTaskLabels(data, id) {
  // First Update Local Database and then send the API request
  const result = await db.issues.update(id, data);
  getIssues();
  api.put(`issues/${id}`, data);
  // First Update Local Database and then send the API request
}


function reloadIssuesFitered() {
  getIssues();
}

const showNewView = inject('showNewView');
const newViewName = ref('');
const newViewDescription = ref('');
const newFilterSharing = ref("onlyme");
function onClickSaveView() {
  if (newViewName.value == "") {
    toast("View name is required", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
    return;
  }
  if (filterQuery.value == null || filterQuery.value.length <= 0) {
    toast("Please select some filters first", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
    return;
  }
  api
    .post(`view`, {
      title: newViewName.value,
      description: newViewDescription.value,
      status: "active",
      sharing: newFilterSharing.value,
      is_fav: 0,
      filters: filterQuery.value,
    })
    .then((response) => {
      if (response.status == 201) {
        showNewView.value = false;
        toast("View created successfully", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Success,
          rtl: false,
        });
      } else {
        toast("There is some problem saving a view", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      }
    });
}

</script>
<template>
  <div class="d-flex flex-wrap justify-content-between topHeader border-bottom"
    style="margin-bottom: 20px; overflow: hidden" v-if="showNewView">
    <div class="col-12 col-md-6 d-flex justify-content-between pb-2">
      <div class="new-wrapper">
        <div class="newVewIcon">
          <NewViewIcon />
        </div>
        <div class="fieldWrapper">
          <input type="text" name="viewname" class="viewname" placeholder="New View" v-model="newViewName" /><br />
          <input type="text" name="viewdescription" class="viewdescription"
            placeholder="High priority issues with labels “New View” in backlog" v-model="newViewDescription" />
        </div>
      </div>
      <button class="border-0 d-flex d-md-none" data-bs-toggle="modal" data-bs-target="#sharingModal">
        <ShareIconBlack />
      </button>
    </div>
    <div
      class="col-12 col-md-6 d-flex flex-md-row flex-row-reverse justify-content-center justify-content-md-end mt-2 pt-2">
      <button class="custom-primary-btn mx-2" @click="onClickSaveView">Save</button>
      <button class="custom-secondary-btn" @click="showNewView = false">Cancel</button>
      <button class="custom-secondary-btn mx-2 d-none d-md-inline" data-bs-toggle="modal"
        data-bs-target="#sharingModal">
        <ShareIconBlack />
        <span v-if="newFilterSharing == 'onlyme'">Only Me</span>
        <span v-if="newFilterSharing == 'everyone'">Everyone</span>
        <span v-if="newFilterSharing == 'project'">Projects</span>
        <span v-if="newFilterSharing == 'team'">Teams</span>
      </button>
    </div>
  </div>
  <div class="">
    <div class="row" style="padding: 20px 0; border-bottom: 1px solid #eaeaea">
      <div class="col-5 pt-1">
        <h1 style="font-size: 32px !important; float: left; padding-right: 15px">
          My Issues
        </h1>
        <LinkIcon />
      </div>
      <div class="col-7">
        <div class="viewbuttoncontainer view-options d-flex align-items-center justify-content-center"
          style="line-height: 32px; text-align: center; float:right; padding:0;">
          <DisplayDropdown />
        </div>
        <div class="viewbuttoncontainer d-flex align-items-center justify-content-center"
          style="padding: 0; text-align: center">
          <Dropdown @reload:issues="reloadIssuesFitered()" :showTitle="true" />
        </div>
        <div class="viewbuttoncontainer d-none d-md-inline">
          <div class="listicon" :class="{ active: listType == 'list' }">
            <ListIcon @click="changeListView('list')" :stroke="listType == 'list' ? '#F96332' : '#7B8497'" />
          </div>
          <div class="kanbanicon" :class="{ active: listType == 'kanban' }">
            <KanbanIcon @click="changeListView('kanban')" :stroke="listType == 'kanban' ? '#F96332' : '#7B8497'" />
          </div>
          <div class="timelineicon" :class="{ active: listType == 'timelline' }">
            <TimelineIcon @click="changeListView('timelline')"
              :stroke="listType == 'timelline' ? '#F96332' : '#7B8497'" />
          </div>
        </div>
      </div>
    </div>
    <div class="row d-none d-md-flex" style="padding: 20px 0">
      <Display :showFilterBoxQuery="showFilterBox" :filterQuery="filterQuery" :statuses="statuses"
        @reload:issues="reloadIssuesFitered()" @save:view="showNewView = $event" />
    </div>
  </div>
  <div class="list_container issuesList" v-if="listType == 'list'">
    <ListTemplate :statuses="statuses" :issues="issues" :grouping="grouping" @updateIssueStatus="editedTaskStatus"
      @updateIssuePriority="editedTaskPriority" @updateTaskAssignee="editedTaskAssignee"
      @updateTaskLabels="updateTaskLabels" />
  </div>
  <div class="kanban_container" v-if="listType == 'kanban'">
    <KanbanTemplate :statuses="statuses" :issues="issues" @updateIssueStatus="editedTaskStatus"
      @updateIssuePriority="editedTaskPriority" />
  </div>
  <!-- popup -->
  <div v-if="showPopup">
    <NewIssue @update:showPopup="showPopup = $event" :statusSelectedFromProps="selectedStatusForNewIssue" />
  </div>



  <div class="modal fade" id="sharingModal" tabindex="-1" aria-labelledby="sharingModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sharingModalLabel">Sharing</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modalWrapper">
          <span>
            <ShareIcon />
            Share With
          </span><br />
          <input type="radio" name="sharing" v-model="newFilterSharing" value="onlyme" />
          Only Me <br />
          <input type="radio" name="sharing" v-model="newFilterSharing" value="everyone" />
          Every One<br />
          <input type="radio" name="sharing" v-model="newFilterSharing" value="team" />
          Teams<br />
          <input type="radio" name="sharing" v-model="newFilterSharing" value="project" />
          Projects<br />
        </div>
        <div class="modal-footer">
          <button type="button" class="custom-secondary-btn" data-bs-dismiss="modal" id="sharingModalCancel">
            Cancel
          </button>
          <button type="button" class="custom-primary-btn" data-bs-dismiss="modal">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.bulkDropdowns button {
  padding: 0;
}

.filterType button {
  padding: 0;
  line-height: 18px;
  color: #3d4046;
}

.filterType button:hover {
  color: #3d4046;
}

.addFilterDropdown button {
  padding: 0;
}

.filterType {
  background: #eaeaea;
  border-radius: 6px;
  height: 42px;
  padding: 10px 16px 10px 16px;
  float: left;
  margin-right: 5px;
}

.filterSubMenu {
  top: 20px;
}

.filterSubMenu button {
  height: 0;
  width: 0;
  padding: 0;
}

.filterSubMenu .statusesUl {
  padding: 10px 0 !important;
}

.viewbuttoncontainer .dropdown-menu {
  padding: 0;
}


.block {
  display: block;
  color: #000;
  text-decoration: none;
  line-height: 2rem;
  cursor: pointer;
}

.filterUl li {
  width: 100%;
  font-size: 14px !important;
  font-weight: 400;
  color: #3d4046;
  min-height: 22px;
  padding: 5px 10px;
  cursor: pointer;
}

.filterUl li.border-bottom {
  border-bottom: 1px solid #eaeaea;
}

.overlay {
  width: 100%;
  height: 100vh;
  background: #000;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
}

.bottomControls {
  position: fixed;
  bottom: 20px;
  width: 83%;
  height: 50px;
  margin: 0 auto;
}

.bottomControls .contents {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px #0000001a;
  height: 50px;
  line-height: 50px;
  border-radius: 8px;
}

span.button {
  min-width: 28px;
  height: 28px;
  box-shadow: 0px 0px 8px 0px #0000001a;
  border-radius: 6px;
  margin: 0 10px;
  padding: 5px;
  border: 1px solid #acacac;
  cursor: pointer;
  color: #333333;
}

span.button .dropdown-menu {
  left: 40%;
  bottom: 300%;
}

span.counter {
  min-width: 28px;
  height: 28px;
  border-radius: 6px 0 0 6px;
  border: 1px dashed #acacac;
  padding: 5px;
  cursor: pointer;
}

span.counter.no-left-border {
  border-left: none;
  border-radius: 0 6px 6px 0;
}

span.counter .fa-close {
  position: inherit;
}

.checkboxwrapper {
  float: left;
  margin-right: 5px;
}

.filter-box {
  float: left;
  padding-right: 32px;
}

.listicon {
  width: 39px;
  height: 34px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.listicon.active,
.kanbanicon.active,
.timelineicon.active {
  background-color: #fff;
}

.kanbanicon {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 39px;
  height: 34px;
  cursor: pointer;
}

.timelineicon {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 39px;
  height: 34px;
  cursor: pointer;
}

.issuesListAssigneeWrapper ul.dropdown-menu {
  min-height: 200px !important;
}

.issueLink {
  color: #333333;
  text-decoration: none;
}

.rotateArrowHead {
  transform: rotateZ(-90deg);
}

.issuesListAssigneeWrapper {
  position: relative;
  cursor: pointer;
  margin-top: -5px;
}

.issuesListAssigneeWrapper .dropdown-menu {
  right: 0;
  bottom: 30px;
  position: absolute;
}

.issuesAssignee .round-placeholder {
  width: 35px;
  height: 35px;
}

.issue-names {
  line-height: 44px;
}

span.issuetext {
  padding-left: 5px;
}

span.issueicon {
  padding-left: 10px;
}

.headerCard {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  z-index: 1;
}

.setting {
  padding: 0 6px 0 0 !important;
}

button:hover {
  background-color: #f96332;
  color: #f4f4f4;
}

.searchBar input {
  border: 1px solid #acacac;
  padding-left: 2.5rem;
}

.flex-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}

.col-3 {
  flex: 0 0 auto;
  width: 20%;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0px !important;
}

.issuesList .statusHeader {
  background: #eaeaea;

  margin-top: 20px !important;

  padding: 6px;
}

.issuewrapper {
  border-bottom: 1px solid #eaeaea;
  border-top: none;
  height: 44px;
}

.statusHeader span {
  padding-right: 5px;
}

@media only screen and (max-width: 768px) {
  .statusesUl a {
    display: block;
  }
}
</style>
