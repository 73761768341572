<template>
  <BDropdown variant="link" toggle-class="text-decoration-none" no-caret class="view-dropdown button-30">
    <template #button-content>
      <DisplayIcon />
      <span style="color: #000;" class="d-none d-md-inline">Display</span>
    </template>
    <div class="row d-flex align-items-center">
      <div class="col-lg-6">
        <p class="dropdown-text">Grouping</p>
      </div>
      <div class="col-lg-6">
        <BDropdown variant="link" v-model="openGroupingDropdown" toggle-class="text-decoration-none" no-caret
          class="inner-dropdown">
          <template #button-content>
            <div class="inner-button" @click.stop="openGroupingDropdown = !openGroupingDropdown">
              <span class="text">{{ groupings[selectedGrouping] }}</span>
              <span class="icon">
                <DownArrowIcon />
              </span>
            </div>
          </template>
          <ul class="view-selection-dropdown">
            <li v-for="(label, index) in groupings" :key="index" @click.stop="selectGrouping(index)">
              <span class="inner-drop-icon">
                <OrangeTick v-if="selectedGrouping == index" />
              </span> <span class="inner-drop-text">{{ label }}</span>
            </li>
          </ul>
        </BDropdown>
      </div>
      <div class="col-lg-6 mt-20">
        <p class="dropdown-text">Ordering</p>
      </div>
      <div class="col-lg-6 mt-20">
        <BDropdown variant="link" v-model="openOrderingDropdown" toggle-class="text-decoration-none" no-caret
          class="inner-dropdown">
          <template #button-content>
            <div class="inner-button" @click.stop="openOrderingDropdown = !openOrderingDropdown">
              <span class="text">{{ orderings[selectedOrdering] }}</span>
              <span class="icon">
                <DownArrowIcon />
              </span>
            </div>
          </template>
          <ul class="view-selection-dropdown">
            <li v-for="(label, index) in orderings" :key="index" @click.stop="selectOrdering(index)">
              <span class="inner-drop-icon">
                <OrangeTick v-if="selectedOrdering == index" />
              </span> <span class="inner-drop-text">{{ label }}</span>
            </li>
          </ul>
        </BDropdown>
      </div>
      <div class="col-lg-6 mt-20">
        <p class="dropdown-text">Completed Issues</p>
      </div>
      <div class="col-lg-6 mt-20">
        <BDropdown variant="link" v-model="openCompletedDropdown" toggle-class="text-decoration-none" no-caret
          class="inner-dropdown">
          <template #button-content>
            <div class="inner-button" @click.stop="openCompletedDropdown = !openCompletedDropdown">
              <span class="text">{{ completions[selectedCompleted] }}</span>
              <span class="icon">
                <DownArrowIcon />
              </span>
            </div>
          </template>
          <ul class="view-selection-dropdown">
            <li v-for="(label, index) in completions" :key="index" @click.stop="selectCompleted(index)">
              <span class="inner-drop-icon">
                <OrangeTick v-if="selectedCompleted == index" />
              </span> <span class="inner-drop-text">{{ label }}</span>
            </li>
          </ul>
        </BDropdown>
      </div>
      <div class="col-lg-9 mt-20">
        <p class="dropdown-text">Show sub-tasks</p>
      </div>
      <div class="col-lg-3 mt-20">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
        </div>
      </div>
      <div class="col-lg-9 mt-20">
        <p class="dropdown-text">Hide parent issue</p>
      </div>
      <div class="col-lg-3 mt-20">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
        </div>
      </div>
      <div class="col-lg-9 mt-20">
        <p class="dropdown-text">Show empty groupings</p>
      </div>
      <div class="col-lg-3 mt-20">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
        </div>
      </div>
      <div class="col-lg-12 mt-20">
        <p class="dropdown-text">Display Properties</p>
      </div>
      <div class="col-lg-12 mt-20">
        <span class="property-labels"> Status </span>
        <span class="property-labels"> Projects </span>
        <span class="property-labels"> Priority </span>
        <span class="property-labels"> Created </span>
        <span class="property-labels"> Assignee </span>
        <span class="property-labels"> Priority </span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mt-3 pt-2 border-top">
        <a href="" class="reset-link">Reset to Default</a>
      </div>
      <div class="col-lg-6 mt-3 pt-2 text-end border-top">
        <a href="" class="default-link">Set as Default</a>
      </div>
    </div>

  </BDropdown>
</template>

<script setup>
import DisplayIcon from '@/components/Icons/DisplayIcon.vue';
import DownArrowIcon from '@/components/Icons/DownArrowIcon.vue';
import OrangeTick from '@/components/Icons/OrangeTick.vue';
import { onMounted, ref, inject, watch } from "vue";
const openGroupingDropdown = ref(false);
const openOrderingDropdown = ref(false);
const openCompletedDropdown = ref(false);

const selectedGrouping = ref('status');
const selectedOrdering = ref('newest');
const selectedCompleted = ref('all');

const groupings = ref({
  status: 'Status',
  assignee: 'Assignee',
  project: 'Project',
  priority: 'Priority',
  cycle: 'Cycle',
  label: 'Label',
  nolabel: 'No Label',
  nogrouping: 'No Grouping'
});

const orderings = ref({
  title: 'Title',
  newest: 'Newest',
  blocked: 'Blocked/Blocking',
  estimate: 'Estimate',
  cycle: 'Cycle',
  lastupdate: 'Last Update',
  duedate: 'Due Date'
});

const completions = ref({
  all: 'All',
  pastday: 'Past day',
  pastweek: 'Past week',
  pastmonth: 'Past month',
  currentcycle: 'Current cycle',
  none: 'None'
});


function selectGrouping(value) {
  selectedGrouping.value = value;
}

function selectOrdering(value) {
  selectedOrdering.value = value;
}

function selectCompleted(value) {
  selectedCompleted.value = value;
}


</script>

<style scoped>
.property-labels {
  background-color: #eaeaea;
  padding: 3px 8px 3px 8px;
  border-radius: 4px;
  color: #3d4046;
  font-size: 12px;
  font-weight: 400;
  margin-right: 3px;
  margin-top: 3px;
}

.reset-link {
  color: #F96332;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
}

.default-link {
  color: #3D4046;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
}

.select-menu {
  padding: 6px;
  height: 37px !important;
}

.dropdown-menu {
  width: 320px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.dropdown-text {
  color: #3d4046;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 14.06px;
  margin-bottom: 0px;
}

.btn-dropdown {
  background-color: #eaeaea;
  padding: 10px 24px 10px 24px;
  border-radius: 8px;
  border: 1px solid #c4c4ca;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  color: #3d4046;
}

option {
  color: #7b8497 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.mr-8 {
  margin-right: 8px;
}

.dropdown-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  color: #3d4046;
  margin-block: 9px;
  padding: 2px;
}

.ms-24 {
  margin-left: 10px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: unset !important;
}
</style>
