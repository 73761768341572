<script setup>
import CupIcon from '@/components/Icons/CupIcon.vue';
import AddCircleIconVue from '@/components/Icons/AddCircleIcon.vue';
import CalendarIcon from '@/components/Icons/CalendarIcon.vue';
import ClockIcon from '@/components/Icons/ClockIcon.vue';
import PriorityIcon from '@/components/Icons/PriorityIcon.vue';
import CreatorIcon from '@/components/Icons/CreatorIcon.vue';
import AssigneeIcon from '@/components/Icons/AssigneeIcon.vue';
import EstimateIcon from '@/components/Icons/EstimateIcon.vue';
import LabelTriangleIcon from '@/components/Icons/LabelTriangleIcon.vue';
import DownArrowIcon from '@/components/Icons/DownArrowIcon.vue';
import LabelIcon from '@/components/Icons/LabelIcon.vue';
import Danger from '../../ToastIcons/ErrorIcon.vue';
import Success from '../../ToastIcons/SuccessIcon.vue';
import DeleteIcon from '@/components/Icons/DeleteIcon.vue';
import InputField from '@/components/Fields/InputField.vue';
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import { onMounted } from 'vue';
import { ref } from 'vue'
import { useRoute } from 'vue-router';
import api from '@/api';
import { useToast } from "vue-toastification";
import '@vuepic/vue-datepicker/dist/main.css'
// import DatePicker from "vue-datepicker";
// import "vue-datepicker";
// import 'bootstrap-datepicker';
//import DatePicker from '@/components/Pages/Project/DatePicker.vue';
// const props = defineProps(['showPopup']);
const props = defineProps(['issueId'])
const route = useRoute()
const emits = defineEmits(['update:showPopup', 'selected:estimate']);
const selectedPriorityDropdown = ref(null);
const name = ref('');
const estimates = ref([
  { 'label': 'No Estimates', 'value': '-1' },
  { 'label': '0 Points', 'value': '0' },
  { 'label': '1 Points', 'value': '1' },
  { 'label': '2 Points', 'value': '2' },
  { 'label': '3 Points', 'value': '3' },
]);
onMounted(() => {

})

function selectEstimate(priority) {
  emits('selected:estimate', priority);
  closePopup();
}

function closePopup() {
  emits('update:showPopup', false);
}
const justMounted = ref(0);
function onClickOutside() {
  if (justMounted.value == 0) {
    justMounted.value = justMounted.value + 1;
    return;
  }
  closePopup();
}
</script>

<template>
  <div class="" style="min-width:180px;" :class="{ 'show': true }">
    <ul class="mb-0">
      <li v-for="(item, index) in estimates" :key="index" style="cursor: pointer;">
        <label v-bind:for="item.value" @click="selectEstimate(item)">
          <div class="row">
            <div class="dropdown-element-exclude d-flex col align-items-center" style="gap: 8px;">
              <EstimateIcon /> {{ item.label }}
            </div>
          </div>
        </label>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.gray-color {
  color: #7B8497;
  font-size: 14px;
}

.mr-1 {
  margin-right: 1rem;
}

.subtask-button {
  width: 80%;
  float: right;
}

.white-btn {
  background: #FFF;
  font-weight: normal;
  color: #000;
  margin-left: 1rem;
}

.white-btn:hover {
  color: #000;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  width: 268px;
  min-height: 200px;
  padding: 10px 14px;
  border-radius: 12px;
  border: 1px solid #C4C4CA;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.dropdown-wrapper {
  position: relative;
}

.dropdown-wrapper .dropdown-menu {
  top: 2.5rem !important;
  left: 0 !important;
  width: auto !important;
}

.dropdown-wrapper .dropdown-menu.position-bottom {
  top: auto !important;
  bottom: 110% !important;
}

.dropdown-wrapper .subtask {
  top: -1000% !important;
  left: -400% !important;
  min-height: 150px !important;
}

/* Add the 'show' class when isDropdownOpen is true */
.dropdown-menu.show {
  display: block;
}

.position-top {
  top: 110%;
  max-height: 351px;
}

.position-bottom {
  bottom: 110%;
  max-height: 392px;
}

.dropdown-menu input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: none;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
}

.dropdown-menu ul li label {
  display: block;
  margin-bottom: 5px;
}

/* Style the checkbox however you like */
.dropdown-menu ul li input[type="checkbox"] {
  margin-right: 5px;
}

.selected-leader {
  background: #EAEAEA;
}

.popup {
  width: 50% !important;
}

.fa-close {
  position: absolute;
  right: 3rem;
  top: 2rem;
  background: #999;
  color: #FFF;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}

.more {
  background-color: #EAEAEA;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  min-width: 60px;
}
</style>