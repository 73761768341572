<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14.42 9.83335C14.42 10.4267 14.2533 10.9867 13.96 11.4667C13.4133 12.3867 12.4067 13 11.2533 13C10.1 13 9.09334 12.38 8.54667 11.4667C8.25334 10.9934 8.08667 10.4267 8.08667 9.83335C8.08667 8.08669 9.50667 6.66669 11.2533 6.66669C13 6.66669 14.42 8.08669 14.42 9.83335Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.44 9.82001H10.0734"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.2534 8.66669V11.0333"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.7933 2.67999V4.15997C13.7933 4.69997 13.4533 5.37332 13.12 5.71332L11.9467 6.74664C11.7267 6.69331 11.4933 6.66665 11.2533 6.66665C9.50666 6.66665 8.08666 8.08665 8.08666 9.83331C8.08666 10.4266 8.25333 10.9867 8.54666 11.4667C8.79333 11.88 9.13333 12.2333 9.54666 12.4866V12.7133C9.54666 13.12 9.28 13.66 8.94 13.86L8 14.4667C7.12666 15.0067 5.91333 14.4 5.91333 13.32V9.75332C5.91333 9.27998 5.64 8.67332 5.37333 8.33999L2.81333 5.64663C2.48 5.30663 2.20667 4.69999 2.20667 4.29999V2.74664C2.20667 1.93998 2.81333 1.33331 3.55333 1.33331H12.4467C13.1867 1.33331 13.7933 1.93999 13.7933 2.67999Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
