<template>
    <node-view-wrapper class="video-view">
        <div class="downloadAttachment" v-if="this.node.attrs.type != 'video/mp4'">
            <span>
                {{ this.node.attrs.filePath }}
            </span>
            <button @click="downloadFile" class="custom-primary-btn float-right">Download File</button>
        </div>
    </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
import api from "@/api";

export default {
    components: {
        NodeViewWrapper,
    },

    props: nodeViewProps,

    methods: {
        increase() {
            this.updateAttributes({
                count: this.node.attrs.count + 1,
            })
        },
        downloadFile() {
            api
                .get(`storage/images/${this.node.attrs.filePath}`, { responseType: "blob" })
                .then((response) => {
                    if (response.status == 200) {
                        const blob = new Blob([response.data], { type: response.data["type"] });
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = this.node.attrs.filePath;
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }
                });
        },
    },
}
</script>

<style lang="scss">
.video-view {
    max-width: 100%;
}

.float-right {
    float: right;
}

.downloadAttachment span {
    float: left;
}

.downloadAttachment {
    width: 100%;
    color: rgb(51, 51, 51);
    margin: 0 auto;
    background-color: #eaeaea;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    overflow: hidden;
    line-height: 2.2rem;
}

.label {
    margin-left: 1rem;
    background-color: #0D0D0D;
    font-size: 0.6rem;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 0;
    padding: 0.25rem 0.75rem;
    border-radius: 0 0 0.5rem 0.5rem;
}

.content {
    margin-top: 1.5rem;
    padding: 1rem;
}
</style>