<script setup>
import { ref, inject } from 'vue'
import ColorSwatches from '../Fields/ColorSwatches.vue';
import { onMounted } from 'vue';
const emits = defineEmits(['update:showPopup', 'update:colorSelected', 'show:customColor', 'update:fontSize', 'update:fontColor', 'update:highlilghtColor', 'save:allOptions']);
const props = defineProps(['fontColor', 'bgColor']);
let selectedColor = ref('');
const fontSizes = ref([10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30]);
const openTextSize = ref(false);
const selectedFontSize = ref(null);
const showColorPopup = ref(false);
const fontColor = ref("#000000");
const bgColor = ref(null);
const customColorType = ref('font');
const slashMenu = ref('false');

onMounted(() => {
  slashMenu.value = localStorage.getItem('fromSlashMenu');
  console.log(slashMenu.value, "From Slashes");
});

function updateFontSize(size) {
  if (slashMenu.value == 'true') {
    selectedFontSize.value = size;
  }
  else {
    emits('update:fontSize', size);
    emits('update:showPopup', false);
  }
}

function updateFontColor(color) {
  if (slashMenu.value == 'true') {
    fontColor.value = color;
  }
  else {
    emits('update:fontColor', color);
    emits('update:showPopup', false);
  }
}

function updateHighlightColor(color) {
  if (slashMenu.value == 'true') {
    bgColor.value = color;
  }
  else {
    emits('update:highlilghtColor', color);
    emits('update:showPopup', false);
  }
}

function saveAll() {
  emits('save:allOptions', {
    size: selectedFontSize.value,
    fontColor: props.fontColor == null ? fontColor.value : props.fontColor,
    highlightColor: props.bgColor == null ? bgColor.value : props.bgColor
  });
  emits('update:showPopup', false);
  localStorage.setItem('fromSlashMenu', false);
}

function updateCustomColor(color) {
  if (customColorType.value == 'font') {
    fontColor.value = color;
  }
  else {
    bgColor.value = color;
  }
  showColorPopup.value = false;
}
function closePopup() {
  emits('update:showPopup', false);
}

function openColorPopupFor(type) {
  customColorType.value = type;
  emits('show:customColor', type);
}
</script>
<template>
  <div class="text-options">
    <i class="fa fa-close custom-fa-close" @click="closePopup"></i>
    <div class="row" style="display: block;">
      <div class="col-md-12 mb-4 font-size-text-options">
        <h4>Size</h4>
        <BDropdown style="width: 100px;" variant="link" v-model="openTextSize" toggle-class="text-decoration-none"
          no-caret>
          <template #button-content>
            <button class="custom-secondary-btn me-4 clear-background">{{ selectedFontSize == null ? '10' :
      selectedFontSize }} pt <i class="fa-solid fa-angle-down"></i></button>
          </template>
          <ul>
            <li style="cursor: pointer; padding-left: 1rem;" @click="updateFontSize(value)"
              v-for="(value, index) in fontSizes" :key="index">{{
      value }} pt </li>
          </ul>
        </BDropdown>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h4>Font Color</h4>
        <ColorSwatches @update:colorSelected="updateFontColor($event)" />
        <br />
        <a @click="openColorPopupFor('font')" class="me-4" style="color: #f96332; cursor: pointer;">Custom Font
          Color</a>
      </div>
      <div class="col-md-6">
        <h4>Background Color</h4>
        <ColorSwatches @update:colorSelected="updateHighlightColor($event)" />
        <br />
        <a @click="openColorPopupFor('bg')" class="me-4" style="color: #f96332; cursor: pointer;">Custom Background
          Color</a>
      </div>
    </div>
    <div class="row" v-if="slashMenu == 'true'">
      <div class="col-md-12 pt-2 text-center">
        <button @click="saveAll" class="custom-primary-btn" style="width: 50%;">Save</button>
      </div>
    </div>
  </div>
</template>
<style>
.text-options .btn-group .btn-link {
  padding: 0;
}

.text-options .btn-group {
  display: block;
}

.clear-background {
  background: none;
}

.font-size-text-options .dropdown-menu {
  min-width: 90px !important;
}
</style>