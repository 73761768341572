<template>
  <div class="dropdown">
    <button
      class="btn btn-dropdown dropdown-toggle"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img :src="addCircle" class="mr-8 mr-8" alt="" />
      Filter
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li>
        <a class="dropdown-item d-flex align-items-center" href="#"
          ><span class="material-symbols-outlined mr-8"> schedule </span
          >Status</a
        >
      </li>
      <li>
        <a class="dropdown-item d-flex align-items-center" href="#"
          ><span class="material-symbols-outlined mr-8"> lens </span>Status</a
        >
      </li>
      <li>
        <a class="dropdown-item d-flex align-items-center" href="#"
          ><span class="material-symbols-outlined mr-8"> schedule </span
          >Status</a
        >
      </li>
      <li>
        <a class="dropdown-item d-flex align-items-center" href="#"
          ><span class="material-symbols-outlined mr-8"> lens </span>Status</a
        >
      </li>
    </ul>
  </div>
</template>

<script setup>
const addCircle = "../../assets/circle.png";
</script>

<style scoped>
.btn-dropdown {
  background-color: #eaeaea;
  padding: 10px 24px 10px 24px;
  border-radius: 8px;
  border: 1px solid #c4c4ca;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  color: #3d4046;
}

.mr-8 {
  margin-right: 8px;
}

.dropdown-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  color: #3d4046;
  margin-block: 9px;
  padding: 2px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: unset !important;
}
</style>
