

import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'

import CommandsList from './CommandsList.vue';
import { openFileExplorer, openTextOptions, setGlobalEditorAttributes, openLinkPopup } from "@/components/Utilities/Utilities.js";
import { getCurrentInstance } from 'vue';
export default {
  items: ({ query }) => {
    return [
      {
        title: 'Heading 1',
        label: 'h1',
        showBorder: false,
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode('heading', { level: 1 })
            .run()
        },
      },
      {
        title: 'Heading 2',
        showBorder: false,
        label: 'h2',
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode('heading', { level: 2 })
            .run()
        },
      },
      {
        title: 'Heading 3',
        showBorder: false,
        label: 'h3',
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode('heading', { level: 3 })
            .run()
        },
      },
      {
        title: 'Text Options',
        showBorder: true,
        label: 't',
        command: ({ editor, range }) => {
          setGlobalEditorAttributes({ gEditor: editor, gRange: range });
          openTextOptions();
        },
      },
      {
        title: 'Bulleted List',
        showBorder: false,
        label: 'bullet',
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .toggleBulletList()
            .run()
        },
      },
      {
        title: 'Numbered List',
        showBorder: false,
        label: 'number',
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .toggleOrderedList()
            .run()
        },
      },
      {
        title: 'Check List',
        label: 'check',
        showBorder: true,
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .toggleTaskList()
            .run()
        },
      },
      {
        title: 'Insert Media',
        showBorder: false,
        label: 'media',
        command: ({ editor, range }) => {
          setGlobalEditorAttributes({ gEditor: editor, gRange: range });
          openFileExplorer(true);
        },
      },
      {
        title: 'Insert Link',
        showBorder: false,
        label: 'link',
        command: ({ editor, range }) => {
          setGlobalEditorAttributes({ gEditor: editor, gRange: range });
          openLinkPopup();
        },
      },
      {
        title: 'Attach files',
        showBorder: true,
        label: 'files',
        command: ({ editor, range }) => {
          setGlobalEditorAttributes({ gEditor: editor, gRange: range });
          openFileExplorer(true);
        },
      },
      {
        title: 'Code Block',
        showBorder: false,
        label: 'code',
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .toggleCodeBlock()
            .run()
        },
      },
      {
        title: 'Divider',
        label: 'divider',
        showBorder: false,
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setHorizontalRule()
            .run()
        },
      },
      {
        title: 'Block Quote',
        showBorder: false,
        label: 'block',
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .toggleBlockquote()
            .run()
        },
      },
      {
        title: 'Table',
        showBorder: false,
        label: 'table',
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
            .run()
        },
      },
    ].filter(item => item.title.toLowerCase().startsWith(query.toLowerCase()))
  },

  render: () => {
    let component
    let popup
    return {
      onStart: props => {
        component = new VueRenderer(CommandsList, {
          // using vue 2:
          // parent: this,
          // propsData: props,
          props,
          editor: props.editor,
        })

        if (!props.clientRect) {
          return
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onInsertMedia() {
        //component.ref.onInsertMedia();
      },

      onUpdate(props) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props) {
        //return;
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        popup[0].destroy()
        component.destroy()
      },
    }
  },
}