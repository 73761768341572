<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="row d-flex align-items-center">
        <div class="col-lg-6 col-12 mb-32">
          <div class="sprints-tabs">
            <button
              class="sprint-tab-button text-btn-dark"
              :class="{ 'text-btn-active': isActive('current Sprint') }"
              @click="setActive('current Sprint')"
            >
              Current Sprint
            </button>
            <button
              class="sprint-tab-button text-btn-dark"
              :class="{ 'text-btn-active': isActive('future Sprint') }"
              @click="setActive('future Sprint')"
            >
              Future Sprint
            </button>
            <button
              class="sprint-tab-button text-btn-dark"
              :class="{ 'text-btn-active': isActive('past Sprint') }"
              @click="setActive('past Sprint')"
            >
              Past Sprint
            </button>
          </div>
        </div>
        <div class="col-lg-6 col-12 mb-32 mt-lg-0 mt-32 text-lg-end text-start">
          <ThemeButton
            buttonText="New Sprint"
            buttonType="button"
            data-bs-toggle="modal"
            data-bs-target="#createSprintModal"
          />
        </div>
      </div>
      <div v-if="isActive('current Sprint')" class="current-sprint-container">
        <div class="row">
          <div class="col-12">
            <div class="content-container w-100 mt-2">
              <a
                class="btn text-start collapse-btn"
                data-bs-toggle="collapse"
                href="#todoCollapse"
                role="button"
                aria-expanded="false"
                aria-controls="todoCollapse"
              >
                To Do ({{ todoLength }})
              </a>
              <div class="collapse" id="todoCollapse">
                <SingleSprint
                  v-for="(sprint, index) in todoSprints"
                  :key="index"
                  :sprintImg="sprint.sprintImg"
                  :sprintName="sprint.sprintName"
                  :sprintDescription="sprint.sprintDescription"
                  :labels="sprint.labels"
                  :end_date="sprint.endDate"
                  :start_date="sprint.startDate"
                  :AssigneeImg="sprint.AssigneeImg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="content-container w-100 mt-2">
              <a
                class="btn text-start collapse-btn"
                data-bs-toggle="collapse"
                href="#inProgressCollapse"
                role="button"
                aria-expanded="false"
                aria-controls="inProgressCollapse"
              >
                In Progress ({{ inProgressLength }})
              </a>
              <div class="collapse" id="inProgressCollapse">
                <SingleSprint
                  v-for="(sprint, index) in inProgress"
                  :key="index"
                  :sprintImg="sprint.sprintImg"
                  :sprintName="sprint.sprintName"
                  :sprintDescription="sprint.sprintDescription"
                  :labels="sprint.labels"
                  :end_date="sprint.endDate"
                  :start_date="sprint.startDate"
                  :AssigneeImg="sprint.AssigneeImg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="content-container w-100 mt-2">
              <a
                class="btn text-start collapse-btn"
                data-bs-toggle="collapse"
                href="#TestingCollapse"
                role="button"
                aria-expanded="false"
                aria-controls="TestingCollapse"
              >
                Testing ({{ testingLength }})
              </a>
              <div class="collapse" id="TestingCollapse">
                <SingleSprint
                  v-for="(sprint, index) in Testing"
                  :key="index"
                  :sprintImg="sprint.sprintImg"
                  :sprintName="sprint.sprintName"
                  :sprintDescription="sprint.sprintDescription"
                  :labels="sprint.labels"
                  :end_date="sprint.endDate"
                  :start_date="sprint.startDate"
                  :AssigneeImg="sprint.AssigneeImg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="content-container w-100 mt-2">
              <a
                class="btn text-start collapse-btn"
                data-bs-toggle="collapse"
                href="#InReviewCollapse"
                role="button"
                aria-expanded="false"
                aria-controls="InReviewCollapse"
              >
                In Review ({{ InRevieLength }})
              </a>
              <div class="collapse" id="InReviewCollapse">
                <SingleSprint
                  v-for="(sprint, index) in Testing"
                  :key="index"
                  :sprintImg="sprint.sprintImg"
                  :sprintName="sprint.sprintName"
                  :sprintDescription="sprint.sprintDescription"
                  :labels="sprint.labels"
                  :end_date="sprint.endDate"
                  :start_date="sprint.startDate"
                  :AssigneeImg="sprint.AssigneeImg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isActive('future Sprint')" class="current-sprint-container">
        <div class="row">
          <div class="col-12">
            <div class="content-container w-100 mt-2">
              <a
                class="btn text-start collapse-btn"
                data-bs-toggle="collapse"
                href="#todoCollapse"
                role="button"
                aria-expanded="false"
                aria-controls="todoCollapse"
              >
                To Do ({{ todoLength }})
              </a>
              <div class="collapse" id="todoCollapse">
                <SingleSprint
                  v-for="(sprint, index) in todoSprints"
                  :key="index"
                  :sprintImg="sprint.sprintImg"
                  :sprintName="sprint.sprintName"
                  :sprintDescription="sprint.sprintDescription"
                  :labels="sprint.labels"
                  :end_date="sprint.endDate"
                  :start_date="sprint.startDate"
                  :AssigneeImg="sprint.AssigneeImg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="content-container w-100 mt-2">
              <a
                class="btn text-start collapse-btn"
                data-bs-toggle="collapse"
                href="#TestingCollapse"
                role="button"
                aria-expanded="false"
                aria-controls="TestingCollapse"
              >
                Testing ({{ testingLength }})
              </a>
              <div class="collapse" id="TestingCollapse">
                <SingleSprint
                  v-for="(sprint, index) in Testing"
                  :key="index"
                  :sprintImg="sprint.sprintImg"
                  :sprintName="sprint.sprintName"
                  :sprintDescription="sprint.sprintDescription"
                  :labels="sprint.labels"
                  :end_date="sprint.endDate"
                  :start_date="sprint.startDate"
                  :AssigneeImg="sprint.AssigneeImg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="content-container w-100 mt-2">
              <a
                class="btn text-start collapse-btn"
                data-bs-toggle="collapse"
                href="#InReviewCollapse"
                role="button"
                aria-expanded="false"
                aria-controls="InReviewCollapse"
              >
                In Review ({{ InRevieLength }})
              </a>
              <div class="collapse" id="InReviewCollapse">
                <SingleSprint
                  v-for="(sprint, index) in Testing"
                  :key="index"
                  :sprintImg="sprint.sprintImg"
                  :sprintName="sprint.sprintName"
                  :sprintDescription="sprint.sprintDescription"
                  :labels="sprint.labels"
                  :end_date="sprint.endDate"
                  :start_date="sprint.startDate"
                  :AssigneeImg="sprint.AssigneeImg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isActive('past Sprint')" class="current-sprint-container">
        <div class="row">
          <div class="col-12">
            <div class="content-container w-100 mt-2">
              <a
                class="btn text-start collapse-btn"
                data-bs-toggle="collapse"
                href="#TestingCollapse"
                role="button"
                aria-expanded="false"
                aria-controls="TestingCollapse"
              >
                Testing ({{ testingLength }})
              </a>
              <div class="collapse" id="TestingCollapse">
                <SingleSprint
                  v-for="(sprint, index) in Testing"
                  :key="index"
                  :sprintImg="sprint.sprintImg"
                  :sprintName="sprint.sprintName"
                  :sprintDescription="sprint.sprintDescription"
                  :labels="sprint.labels"
                  :end_date="sprint.endDate"
                  :start_date="sprint.startDate"
                  :AssigneeImg="sprint.AssigneeImg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="content-container w-100 mt-2">
              <a
                class="btn text-start collapse-btn"
                data-bs-toggle="collapse"
                href="#InReviewCollapse"
                role="button"
                aria-expanded="false"
                aria-controls="InReviewCollapse"
              >
                In Review ({{ InRevieLength }})
              </a>
              <div class="collapse" id="InReviewCollapse">
                <SingleSprint
                  v-for="(sprint, index) in Testing"
                  :key="index"
                  :sprintImg="sprint.sprintImg"
                  :sprintName="sprint.sprintName"
                  :sprintDescription="sprint.sprintDescription"
                  :labels="sprint.labels"
                  :end_date="sprint.endDate"
                  :start_date="sprint.startDate"
                  :AssigneeImg="sprint.AssigneeImg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="row d-flex align-items-center">
        <div class="col-12 text-end mb-32">
          <FilterDropdown />
          <DisplayDropdown />
        </div>
      </div>
      <div class="col-12">
        <div class="detailcard">
          <div class="row ">
            <div class="col-6 mb-4">
              <div class="container-text d-flex align-items-center">
                <span class="ui-phase mr-12">UI Phase </span>
                <span class="material-symbols-outlined ui-phase">
                  star
                </span>
              </div>
            </div>
            <div class="col-lg-6 mb-4 text-end">
              <MoreOptionDropdown />
            </div>
          </div>
          <apexchart
            type="line"
            :options="chartOptions"
            :series="series"
          ></apexchart>
          <AssigneeTabs v-for="(item, index) in items" :key="index" :assigneeImg="item.assigneeImg" :assigneeName="item.assigneeName" :completedTasksPercentage="item.completedTasksPercentage" :totalTask="item.totaltasks"  />
        </div>
      </div>
    </div>
  </div>

  <!-- Create Sprint Modal -->

  <div
    class="modal fade"
    id="createSprintModal"
    tabindex="-1"
    aria-labelledby="createSprintModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h5 class="modal-title sprint-title" id="exampleModalLabel">
            New Sprint
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-4">
              <CustomInput
                v-model="f_name"
                inputType="text"
                inputPlaceHolder="Enter"
                inputName="sprint_name"
                LabelText="Sprint Name (Optional)"
              />
            </div>
            <div class="col-lg-4">
              <CustomInput
                v-model="start_date"
                inputType="date"
                inputPlaceHolder="08/04/23"
                inputName="start_date"
                LabelText="Start Date"
              />
              <p v-if="isStartDateEmpty" class="text-danger">
                Start date is required.
              </p>
            </div>
            <div class="col-lg-4">
              <CustomInput
                v-model="end_date"
                inputType="date"
                inputPlaceHolder="12/04/23"
                inputName="end_date"
                LabelText="End Date"
              />
              <p v-if="isEndDateEmpty" class="text-danger">
                End date is required.
              </p>
            </div>
            <div class="col-lg-4 mt-32">
              <label for="story_points">Burndown Allotted</label>
              <select
                class="form-select customSelect form-select-lg mb-3"
                aria-label=".form-select-lg example"
                name="story_points"
                v-model="selectedItem"
              >
                <option value="Story Points" selected></option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div class="col-lg-3 mt-32">
              <CustomInput
                v-model="amount"
                inputType="text"
                inputPlaceHolder="Amount"
                inputName="amount"
                LabelText="Amount"
              />
              <p v-if="isAmountEmpty" class="text-danger">
                Amount is required.
              </p>
            </div>
            <div class="col-lg-12">
              <label for="goals">Sprint Goals</label>
              <textarea
                class="form-control w-100 shadow-0"
                rows="10"
                v-model="goals"
                placeholder="Enter"
              ></textarea>
              <p v-if="isGoalsEmpty" class="text-danger">
                Sprint goals are required.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-cancell" data-bs-dismiss="modal">
            Cancel
          </button>
          <button @click="handleSave" type="button" class="btn btn-save">
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import ThemeButton from "../General/ThemeButton.vue";
import SingleSprint from "../sprints/SingleSprint.vue";
import CustomInput from "../General/CustomInput.vue";
import FilterDropdown from "../General/FilterDropdown.vue";
import DisplayDropdown from "../General/DisplayDropdown.vue";
import MoreOptionDropdown from "../General/MoreOptionsDropdown.vue";
import AssigneeTabs from "../General/AssigneeTabs.vue";
// Active tab logic
const activeTab = ref("current Sprint");

const setActive = (tab) => {
  activeTab.value = tab;
};

const isActive = (tab) => {
  return activeTab.value === tab;
};

const items = [
  { assigneeImg: '../../../assets/Avatar.png', assigneeName: 'Name 1', completedTasksPercentage: '50%', totaltasks: "32" },
  { assigneeImg: '../../../assets/Avatar.png', assigneeName: 'Name 2', completedTasksPercentage: '80%', totaltasks: "32" },
];

const chartOptions = ref({
  chart: {
    type: "line",
  },
  xaxis: {
    categories: ["22 April", "14 September", "13 August"],
  },
});

const series = ref([
  {
    name: "Completed",
    data: [10, 20, 30, 40, 50, 12, 67, 34],
  },
  {
    name: "In Progress",
    data: [5, 15, 25, 35, 45, 24, 65, 16],
  },
  {
    name: "Started",
    data: [2, 8, 15, 20, 25, 45, 34, 33],
  },
]);

const todoSprints = [
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  // Add more sprint objects as needed
];

const inProgress = [
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  // Add more sprint objects as needed
];

const Testing = [
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  // Add more sprint objects as needed
];

const InReview = [
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  {
    startDate: "22 May 2023",
    endDate: "29 May 2023",
    sprintName: "MS-183",
    sprintDescription: "Test on Test 1d",
    sprintImg: "../../../assets/layer.png",
    AssigneeImg: "../../../assets/Avatar.png",
    labels: [
      { name: "Phase 1", status: "Important" },
      { name: "Phase 2", status: "Not Important" },
      { name: "Phase 3", status: "Medium" },
    ],
  },
  // Add more sprint objects as needed
];

const todoLength = todoSprints.length;

const inProgressLength = inProgress.length;

const testingLength = Testing.length;

const InRevieLength = InReview.length;

const f_name = ref("");

const start_date = ref("");

const end_date = ref("");

const amount = ref("");

const selectedItem = ref("Story Points");

const goals = ref("");

const isStartDateEmpty = ref(false);
const isEndDateEmpty = ref(false);
const isAmountEmpty = ref(false);
const isGoalsEmpty = ref(false);

const handleSave = () => {
  isStartDateEmpty.value = start_date.value.trim() === "";
  isEndDateEmpty.value = end_date.value.trim() === "";
  isAmountEmpty.value = amount.value.trim() === "";
  isGoalsEmpty.value = goals.value.trim() === "";

  if (
    isStartDateEmpty.value ||
    isEndDateEmpty.value ||
    isAmountEmpty.value ||
    isGoalsEmpty.value
  ) {
    return;
  }
  alert("Saving...");
};
</script>

<style scoped>
.detailcard {
  border: 1px solid #c4c4ca;
  border-radius: 12px;
  padding: 24px;
}

.mr-12{
  margin-right: 12px;
}

.ui-phase {
  color: #7b8497;
  font-size: 20px !important;
  font-weight: 500;
  line-height: 28px;
}

.mb-32 {
  margin-bottom: 32px;
}
.sprints-tabs {
  padding: 10px 16px 10px 16px;
  background: #eaeaea;
  width: 301px;
  border-radius: 6px;
}

.sprint-tab-button {
  padding: 10px 8px 10px 8px;
  line-height: 14.06px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 6px;
  border: none;
}

.text-btn-dark {
  color: #3d4046;
  background-color: #eaeaea;
}

.text-btn-active {
  color: #ffffff;
  background-color: #f96332;
}

.mt-32 {
  margin-top: 32px;
}

.modal.show .modal-dialog {
  transition: transform 0.3s ease-out !important;
  transform: translate(0, -50px) !important;
}

.collapse-btn {
  background-color: #eaeaea;
  height: 40px;
  color: #2a2c32;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
}

.sprint-title {
  color: #2a2c32 !important;
  line-height: 42px !important;
  font-size: 32px !important;
  font-weight: 600 !important;
}

#createSprintModal {
  border-radius: 6px;
}

.customSelect {
  border-radius: 8px;
  border: 1px solid #c4c4ca;
  color: #7b8497 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  height: 43px;
}

* {
  outline: none !important;
  box-shadow: none !important;
}

label {
  color: #2a2c32;
  font-size: 14px;
  line-height: 16.41px;
  font-weight: 500;
}

.btn-cancell {
  background-color: #c4c4ca;
  padding: 10px 32px 10px 32px;
  border-radius: 8px;
  color: #7b8497;
  font-size: 16px;
  font-weight: 500;
}

.btn-save {
  background-color: #f96332;
  padding: 10px 32px 10px 32px;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

::placeholder {
  color: #7b8497 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.text-danger {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
</style>
