<script setup>
import { onMounted, ref, inject, watch } from "vue";
import { Container, Draggable } from "vue-dndrop";
import KanbanSaturn from "@/components/Icons/KanbanSaturn.vue";
import KanbanCalendar from "@/components/Icons/KanbanCalendar.vue";
import KanbanFlag from "@/components/Icons/KanbanFlag.vue";
import KanbanLight from "@/components/Icons/KanbanLight.vue";
import KanbanProgress from "@/components/Icons/KanbanProgress.vue";
import KanbanSite from "@/components/Icons/KanbanSite.vue";
import DisplayAvatar from "../Profile/DisplayAvatar.vue";
import KanbanBlock from "@/components/Icons/KanbanBlock.vue";
import AddCircleIconVue from '@/components/Icons/AddCircleIcon.vue';
import NewIssue from '@/components/Pages/Issues/NewIssue.vue';
import PriorityIcon from '@/components/Icons/PriorityIcon.vue';
import PriorityDropdown from '@/components/Pages/General/PriorityDropdown.vue';

const props = defineProps(['issues', 'statuses']);
const emits = defineEmits(['updateIssueStatus', 'updateIssuePriority']);
const selectedStatusForNewIssue = ref('');
const showPopup = ref(false);
const localIssues = ref(props.issues);
const issuesFiltered = inject('issuesFiltered');
const workspaceSlug = ref('temp');
workspaceSlug.value = JSON.parse(localStorage.getItem('workspace'));
watch(issuesFiltered, (newValue, oldValue) => {
  scene.value = {
    type: 'container',
    props: {
      orientation: 'horizontal',
    },
    children: setupColumns(),
  };
});

function togglePriorityDropdown(columnIndex, cardIndex) {
  scene.value.children[columnIndex]['children'][cardIndex].props['openPriority'] = !scene.value.children[columnIndex]['children'][cardIndex].props['openPriority'];
}
function showNewIssueWithStatus(index) {
  selectedStatusForNewIssue.value = props.statuses[index];
  showPopup.value = true;
}

function getCardPayload(columnId) {
  return (index) => {
    return scene.value.children.filter((p) => p.id === columnId)[0].children[
      index
    ];
  };
}

function applyDrag(arr, dragResult) {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
}

const dropPlaceholderOptions = {
  className: "drop-preview",
  animationDuration: "150",
  showOnTop: true,
};

function setupColumns() {
  let finalReturnArray = [];
  for (let sta of props.statuses) {
    let columnChild = {
      id: `column${sta.id}`,
      type: 'container',
      name: sta.label,
      props: {
        orientation: 'vertical',
        className: 'card-container',
        icon: sta.icon
      },
    };
    let issuesArray = [];
    if (props.issues['status' + sta.label] != undefined) {
      for (let issue of props.issues['status' + sta.label]) {
        let issueDic = {
          type: 'draggable',
          id: issue.id,
          props: {
            className: 'card',
            project: issue.project != null ? issue.project.name : "",
            createdBy: issue.createdBy,
            priority: issue.priority,
            labelsArray: issue.labelsArray,
            openPriority: false,
            openLabels: false
          },
          number: issue.identifier,
          data: issue.title
        };
        issuesArray.push(issueDic);
      }
      columnChild['children'] = issuesArray;
      finalReturnArray.push(columnChild);
    }
    else {
      columnChild['children'] = [];
      finalReturnArray.push(columnChild);
    }
  }
  return finalReturnArray;
}
const scene = ref({
  type: 'container',
  props: {
    orientation: 'horizontal',
  },
  children: setupColumns(),
});
function onColumnDrop(dropResult) {
  const localScene = Object.assign({}, scene.value);
  localScene.children = applyDrag(localScene.children, dropResult);
  scene.value = localScene;
}
let counter = 0;
function onCardDrop(columnId, dropResult) {
  if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
    const localscene = Object.assign({}, scene.value);
    const column = localscene.children.filter((p) => p.id === columnId)[0];
    const columnIndex = localscene.children.indexOf(column);

    const newColumn = Object.assign({}, column);
    newColumn.children = applyDrag(newColumn.children, dropResult);
    localscene.children.splice(columnIndex, 1, newColumn);
    scene.value = localscene;
    if (dropResult.addedIndex !== null) {

      let status = null;
      for (let sta of props.statuses) {
        if (`column${sta.id}` == columnId) {
          status = sta;
          break;
        }
      }
      let issueTemp = getIssueFromCardId(dropResult.payload.id);
      if (status != null && issueTemp != null) {
        emits('updateIssueStatus', issueTemp, status);
      }
    }
    counter++;
  }
}

function getIssueFromCardId(cardId) {
  let issueTemp = null;
  for (let sta of props.statuses) {
    let found = false;
    if (props.issues['status' + sta.label] != undefined) {
      for (let iss of props.issues['status' + sta.label]) {
        if (iss.id == cardId) {
          issueTemp = iss;
          found = true;
          break;
        }
      }
    }
    if (found) {
      break;
    }
  }
  return issueTemp;
}

function dragStart() {

}
function log(...params) {

}

function editedTaskPriority(columnIndex, cardIndex, cardId, prio) {
  scene.value.children[columnIndex]['children'][cardIndex].props['priority'] = prio;
  let issueTemp = getIssueFromCardId(cardId);
  emits('updateIssuePriority', issueTemp, prio);
}

function toggleLabelDropdown(columnIndex, cardIndex) {
  scene.value.children[columnIndex]['children'][cardIndex].props['openLabels'] = !scene.value.children[columnIndex]['children'][cardIndex].props['openLabels'];
}

</script>
<template>
  <div>
    <Container orientation="horizontal" @drop="onColumnDrop($event)" drag-handle-selector=".column-drag-handle"
      @drag-start="dragStart">
      <Draggable v-for="(column, columnIndex) in scene.children" :key="column.id">
        <div class="card-container">
          <div class="card-column-header">
            <span class="column-drag-handle" v-html="column.props.icon"></span>
            <span class="statusLabel">{{ column.name }}</span>
            <span class="issueCounter">({{ column.children != undefined ? column.children.length : '0'
              }})</span>
            <span style="float: right;">
              <AddCircleIconVue @click="showNewIssueWithStatus(column.id)" style="cursor: pointer;" />
            </span>
          </div>
          <Container group-name="col" @drop="(e) => onCardDrop(column.id, e)" drag-class="card-ghost"
            :get-child-payload="getCardPayload(column.id)" drop-class="card-ghost-drop"
            :drop-placeholder="dropPlaceholderOptions">
            <Draggable v-for="(card, cardIndex) in column.children" :key="card.id">
              <div class="card" :id="card.id">
                <div class="lineone">
                  <span class="issueNumber">
                    <KanbanSaturn />
                    {{ card.number }}
                    <span class="projectTitle"> > {{ card.props.project }}</span>
                  </span>
                  <span style="float: right; height: 30px;">
                    <DisplayAvatar :avatar="card.props.createdBy == null ? null : card.props.createdBy['avatar']" />
                  </span>
                </div>
                <div class="linetwo">
                  <router-link class="issueLink card-text"
                    :to="`/${workspaceSlug.slug}/issue/${card.number.toString().replaceAll(' ', '')}`">
                    <span style="cursor: pointer;">{{ card.data }}</span>
                  </router-link>
                </div>
                <div class="linethree">
                  <div class="priorityWrapper">
                    <BDropdown variant="link" v-model="card.props['openPriority']" toggle-class="text-decoration-none"
                      no-caret>
                      <template #button-content>
                        <PriorityIcon style="width:16px; height:16px;"
                          v-if="card.props.priority == null || card.props.priority.value == null" />
                        <span v-if="card.props.priority != null && card.props.priority.value != null"
                          v-html="card.props.priority.icon"></span>
                      </template>
                      <PriorityDropdown v-if="card.props['openPriority']"
                        @selected:item="editedTaskPriority(columnIndex, cardIndex, card.id, $event)"
                        @update:showPopup="card.props['openPriority'] = $event" />
                    </BDropdown>
                  </div>
                  <div class="subtasks">
                    <span>
                      <KanbanProgress /> <span>2/6</span>
                    </span>
                  </div>
                  <div class="calendaricon">
                    <KanbanCalendar />
                  </div>
                  <div class="allLabels">
                    <div class="labelWrapper" v-if="card.props['labelsArray'].length <= 2" style="float: right;">
                      <div v-for="(label, indexlabel) in card.props['labelsArray']" :key="indexlabel" :style="{
      background: `rgba(${label.colorArray[0]},${label.colorArray[1]},${label.colorArray[2]}, 0.3)`,
      'border-radius': '20px',
      padding: '5px 10px',
      'font-size': '10px',
      'margin-right': '5px',
      'display': 'inline-block',
      'margin-bottom': '5px'
    }">
                        <span :style="{
      background: label.color,
      width: '8px',
      height: '8px',
      'border-radius': '10px',
      'display': 'inline-block'
    }"></span>
                        {{ label.label }}
                      </div>
                    </div>
                    <div style="float: right;" class="labelWrapper" @click="toggleLabelDropdown(columnIndex, cardIndex)"
                      v-if="card.props['labelsArray'].length > 2">
                      <div :style="{
      background: `rgba(${card.props['labelsArray'][0]['colorArray'][0]},${card.props['labelsArray'][0]['colorArray'][1]},${card.props['labelsArray'][0]['colorArray'][2]}, 0.3)`,
      'border-radius': '20px',
      padding: '5px 10px',
      'font-size': '10px',
      'margin-right': '5px',
      'display': 'inline-block',
      'margin-bottom': '5px'
    }">
                        <span :style="{
      background: card.props['labelsArray'][0]['color'],
      width: '8px',
      height: '8px',
      'border-radius': '10px',
      'display': 'inline-block'
    }"></span>
                        {{ card.props['labelsArray'].length + ' labels' }}
                      </div>
                      <div class="dropdown-menu position-bottom"
                        style="min-width:120px !important; width:120px !important;"
                        :class="{ 'show': card.props['openLabels'] }">
                        <ul class="mb-0">
                          <li v-for="(itemLabel, index) in card.props['labelsArray']" :key="index">
                            <div class="row">
                              <div :style="{
      background: `rgba(${itemLabel.colorArray[0]},${itemLabel.colorArray[1]},${itemLabel.colorArray[2]}, 0.3)`,
      'border-radius': '20px',
      padding: '5px 10px',
      'font-size': '10px',
      'margin-right': '5px',
      'display': 'inline-block',
      'margin-bottom': '5px'
    }">
                                <span :style="{
      background: itemLabel.color,
      width: '8px',
      height: '8px',
      'border-radius': '10px',
      'display': 'inline-block'
    }"></span>
                                {{ itemLabel.label }}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="linefour">
                  <div class="site">
                    <KanbanSite /> <span> Website Builder Website Builder Website Builder </span>
                  </div>
                  <div class="flag">
                    <KanbanFlag /> <span> Q1 </span>
                  </div>
                  <div class="light">
                    <KanbanLight /> <span> 2 </span>
                  </div>
                  <div class="block">
                    <KanbanBlock />
                  </div>
                </div>
              </div>
            </Draggable>
          </Container>
        </div>
      </Draggable>
    </Container>
  </div>
  <div v-if="showPopup">
    <NewIssue @update:showPopup="showPopup = $event" :statusSelectedFromProps="selectedStatusForNewIssue" />
  </div>
</template>

<style>
.kanban_container {
  overflow: scroll;
  height: 90vh;
}

.kanban_container .dndrop-container.vertical {
  height: 100vh;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card {
  width: 100%;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  height: 146px;
  background: #FFFFFF;
  padding: 10px;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper {
  margin-top: 10px;
  box-shadow: 0px 0px 8px 0px #0000001A;
  border-radius: 8px;
  overflow: inherit;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card span.issueNumber {
  color: #3D4046;
  font-size: 12px;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card span.projectTitle {
  color: #7B8497;
  padding-left: 5px;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card p {
  font-size: 16px;
  font-weight: 500;
  color: #3D4046;

}

.subtasks {
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  width: 51px;
  height: 24px;
  float: left;
  text-align: center;
  margin-left: 5px;
}

.subtasks span {
  color: #7B8497;
  font-size: 12px !important;

}

.calendaricon {
  margin: 0 10px;
  float: left;
}

.linefour {
  margin-top: 10px;
}

.linefour .site {
  float: left;
  border: 1px solid #EAEAEA;
  width: 90px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
}

.linefour .flag {
  float: left;
  border: 1px solid #EAEAEA;
  width: 48px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
  margin: 0 5px;
}

.linefour .light {
  float: left;
  border: 1px solid #EAEAEA;
  width: 39px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
}

.linefour .block {
  float: left;
  border: 1px solid #EAEAEA;
  width: 28px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
  margin-left: 5px;
}

.linefour span {
  color: #7B8497;
  font-size: 12px !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.kanban_container .card-container {
  width: 260px;
  margin-right: 15px;
}

.kanban_container .card-column-header {
  width: 100%;
  background: #EAEAEA;
  border-radius: 8px;
  padding: 0 10px;
  line-height: 40px;
}

.kanban_container .card-column-header .statusLabel {
  padding-left: 8px;
  color: #2A2C32;
  font-size: 14px;
  font-weight: 500 !important;
}

.kanban_container .card-column-header .issueCounter {
  padding-left: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #7B8497;

}
</style>