<template>
    <div>Welcome To the App</div>
</template>

<script>

export default {
  name: 'HomeComponent',
}
</script>
