<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      opacity="0.4"
      d="M21.5 8.37V15.82C21.5 15.97 21.45 16.12 21.32 16.25C19.87 17.71 17.29 20.31 15.81 21.8C15.68 21.94 15.51 22 15.34 22H7.37C4.68 22 2.5 19.82 2.5 17.13V8.37C2.5 5.68 4.68 3.5 7.37 3.5H16.63C19.32 3.5 21.5 5.68 21.5 8.37Z"
      fill="#7B8497"
    />
    <path
      d="M8.28998 6.29C7.86998 6.29 7.53998 5.95 7.53998 5.54V2.75C7.53998 2.34 7.86998 2 8.28998 2C8.70998 2 9.03998 2.34 9.03998 2.75V5.53C9.03998 5.95 8.70998 6.29 8.28998 6.29Z"
      fill="#7B8497"
    />
    <path
      d="M15.71 6.29C15.29 6.29 14.96 5.95 14.96 5.54V2.75C14.96 2.33 15.3 2 15.71 2C16.13 2 16.46 2.34 16.46 2.75V5.53C16.46 5.95 16.13 6.29 15.71 6.29Z"
      fill="#7B8497"
    />
    <path
      d="M14.78 12.71H7.35999C6.93999 12.71 6.60999 12.37 6.60999 11.96C6.60999 11.55 6.94999 11.21 7.35999 11.21H14.78C15.2 11.21 15.53 11.55 15.53 11.96C15.53 12.37 15.2 12.71 14.78 12.71Z"
      fill="#7B8497"
    />
    <path
      d="M12 16.42H7.35999C6.93999 16.42 6.60999 16.08 6.60999 15.67C6.60999 15.25 6.94999 14.92 7.35999 14.92H12C12.42 14.92 12.75 15.26 12.75 15.67C12.75 16.08 12.42 16.42 12 16.42Z"
      fill="#7B8497"
    />
    <path
      d="M21.5 15.82C21.5 15.97 21.45 16.12 21.32 16.25C19.87 17.71 17.29 20.31 15.81 21.8C15.68 21.94 15.51 22 15.34 22C15.01 22 14.69 21.74 14.69 21.36V17.86C14.69 16.4 15.93 15.19 17.45 15.19C18.4 15.18 19.72 15.18 20.85 15.18C21.24 15.18 21.5 15.49 21.5 15.82Z"
      fill="#7B8497"
    />
  </svg>
</template>
