<script setup>
import { onMounted, ref, inject, watch } from "vue";
import { Container, Draggable } from "vue-dndrop";
import KanbanSaturn from "@/components/Icons/KanbanSaturn.vue";
import KanbanCalendar from "@/components/Icons/KanbanCalendar.vue";
import KanbanFlag from "@/components/Icons/KanbanFlag.vue";
import KanbanLight from "@/components/Icons/KanbanLight.vue";
import KanbanProgress from "@/components/Icons/KanbanProgress.vue";
import KanbanSite from "@/components/Icons/KanbanSite.vue";
import KanbanBlock from "@/components/Icons/KanbanBlock.vue";
import AssigneeDropdown from "@/components/Pages/General/AssigneeDropdown.vue";
import AddCircleIconVue from '@/components/Icons/AddCircleIcon.vue';
import NewIssue from '@/components/Pages/Issues/NewIssue.vue';
import PriorityIcon from '@/components/Icons/PriorityIcon.vue';
import DisplayAvatar from "@/components/Pages/Profile/DisplayAvatar.vue";
import CheckBoxInput from "@/components/CheckBoxInput.vue";
import ClockIcon from "@/components/Icons/ClockIcon.vue";
import PriorityDropdown from "@/components/Pages/General/PriorityDropdown.vue";
import StatusDropdown from "@/components/Pages/General/StatusDropdown.vue";
import DownArrowIcon from "@/components/Icons/DownArrowIcon.vue";
import DisplayIcon from "@/components/Icons/DisplayIcon.vue";
import Backlog from "@/components/Icons/BacklogIcon.vue";
import NotStarted from "@/components/Icons/NotStartedIcon.vue";
import Started from "@/components/Icons/StartedIcon.vue";
import Testing from "@/components/Icons/TestingIcon.vue";
import Pending from "@/components/Icons/PendingIcon.vue";
import Completed from "@/components/Icons/CompletedIcon.vue";
import Cancelled from "@/components/Icons/CancelledIcon.vue";
import {
  db,
  defaultDatabase,
  initiateDatabase,
} from "@/localdatabase/dbConfig";

const props = defineProps(['issues', 'statuses', 'grouping']);
const emits = defineEmits(['updateIssueStatus', 'updateIssuePriority', 'updateTaskAssignee', 'updateTaskLabels']);
const selectedStatusForNewIssue = ref('');
const showPopup = ref(false);
const localIssues = ref(props.issues);
const issuesFiltered = inject('issuesFiltered');
const workspaceSlug = ref('temp');
workspaceSlug.value = JSON.parse(localStorage.getItem('workspace'));
watch(issuesFiltered, (newValue, oldValue) => {

});

const selectedLabels = ref(null);
async function editedTaskPriority(issue, priority) {
  emits('updateIssuePriority', issue, priority);
}

async function editedTaskStatus(issue, status) {
  emits('updateIssueStatus', issue, status);
}

async function editedTaskAssignee(issue, mem) {
  emits('updateTaskAssignee', issue, mem);
}
async function updateTaskLabels(labels, issueId) {
  emits('updateTaskLabels', labels, issueId);
}

async function updateBulkTasksPriority(priority) {
  const allIssues = await getAllIssuesForBulkAction();
  for (let issue of allIssues) {
    editedTaskPriority(issue, priority);
  }
}
async function updateBulkTasksStatus(status) {
  const allIssues = await getAllIssuesForBulkAction();
  for (let issue of allIssues) {
    await editedTaskStatus(issue, status);
  }
}

async function updateBulkTasksAssigneToMe() {
  const userId = localStorage.getItem("userId");
  const userToAssign = await db.users.get({ id: parseInt(userId) });
  const allIssues = await getAllIssuesForBulkAction();
  for (let issue of allIssues) {
    await editedTaskAssignee(issue, userToAssign);
  }
}

async function updateBulkTasksAssigneToUser(userToAssign) {
  const allIssues = await getAllIssuesForBulkAction();
  for (let issue of allIssues) {
    await editedTaskAssignee(issue, userToAssign);
  }
}


async function editedBulkTaskLabels(labelsArrayTemp) {
  selectedLabels.value = labelsArrayTemp;
  const allIssues = await getAllIssuesForBulkAction();
  for (let issue of allIssues) {
    await updateTaskLabels({ labels: selectedLabels.value.join(",") }, issue["id"]);
  }
}

const selectedIssuesArray = ref([]);
const selectedIssuesForBulkChange = ref([]);
function toggleSelectedIssue(issueId) {
  if (selectedIssuesArray.value.includes(issueId)) {
    selectedIssuesArray.value.splice(
      selectedIssuesArray.value.indexOf(issueId),
      1
    );
  } else {
    selectedIssuesArray.value.push(issueId);
  }
}

async function getAllIssuesForBulkAction() {
  return await db.issues.where('id').anyOf(selectedIssuesArray.value).toArray();
}
</script>
<template>
  <div class="row" v-for="(status, index) in props.statuses" :key="index">
    <div class="row statusHeader">
      <div class="col-md-4 p-0">
        <div class="d-flex flex-wrap">
          <div class="mx-2">
            <DownArrowIcon :class="{ rotateArrowHead: !status.open }" style="cursor: pointer"
              @click="status.open = !status.open" />
          </div>
          <div class="">
            <Backlog v-if="status.value == 'backlog'" :fill="status.color" />
            <NotStarted v-if="status.value == 'notstarted'" :fill="status.color" />
            <Started v-if="status.value == 'started'" :fill="status.color" />
            <Testing v-if="status.value == 'testing'" :fill="status.color" />
            <Pending v-if="status.value == 'pending'" :fill="status.color" />
            <Completed v-if="status.value == 'completed'" :fill="status.color" />
            <Cancelled v-if="status.value == 'cancelled'" :fill="status.color" />
            &nbsp;
            <span>{{ status.label }}</span>
            <span>({{
    issues["status" + status.label] != undefined
      ? issues["status" + status.label].length
      : "0"
  }})</span>
            <span>
              <AddCircleIconVue @click="showNewIssueWithStatus(index)" style="cursor: pointer" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-if="status.open == true">
      <div class="issuewrapper" v-for="(issue, indexissue) in props.issues[props.grouping + status.label]"
        :key="indexissue">
        <div class="">
          <div class="d-flex justify-content-between">
            <div class="checkboxwrapper">
              <CheckBoxInput @click="toggleSelectedIssue(issue.id)"
                :model-value="selectedIssuesArray.includes(issue.id)" />
            </div>
            <div class="statusWrapper">
              <BDropdown variant="link" v-model="issue['openStatus']" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <ClockIcon style="width: 16px; height: 16px" v-if="issue.status == null" />
                  <Backlog v-if="issue.issuestatus != null &&
    issue.issuestatus.label == 'backlog'
    " :fill="issue.issuestatus.color" />
                  <NotStarted v-if="issue.issuestatus != null &&
    issue.issuestatus.label == 'notstarted'
    " :fill="issue.issuestatus.color" />
                  <Started v-if="issue.issuestatus != null &&
    issue.issuestatus.label == 'started'
    " :fill="issue.issuestatus.color" />
                  <Testing v-if="issue.issuestatus != null &&
    issue.issuestatus.label == 'testing'
    " :fill="issue.issuestatus.color" />
                  <Pending v-if="issue.issuestatus != null &&
    issue.issuestatus.label == 'pending'
    " :fill="issue.issuestatus.color" />
                  <Completed v-if="issue.issuestatus != null &&
    issue.issuestatus.label == 'completed'
    " :fill="issue.issuestatus.color" />
                  <Cancelled v-if="issue.issuestatus != null &&
    issue.issuestatus.label == 'cancelled'
    " :fill="issue.issuestatus.color" />
                </template>
                <StatusDropdown :workflowId="issue.issueType.workflow_id" v-if="issue['openStatus']"
                  @selected:item="editedTaskStatus(issue, $event)" @update:showPopup="issue['openStatus'] = $event" />
              </BDropdown>
            </div>
            <div class="issueNumberWrapper">
              <router-link class="issueLink" :to="`/${workspaceSlug.slug}/issue/${issue.identifier}`">
                <span class="setting" style="cursor: pointer">{{ issue.identifier }}</span>
              </router-link>
            </div>
            <div class="priorityWrapper">
              <BDropdown variant="link" v-model="issue['openPriority']" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <PriorityIcon style="width: 16px; height: 16px" v-if="issue.priority == null || issue.priority.value == null
    " />
                  <span v-if="issue.priority != null && issue.priority.value != null
    " v-html="issue.priority.icon"></span>
                </template>
                <PriorityDropdown v-if="issue['openPriority']" @selected:item="editedTaskPriority(issue, $event)"
                  @update:showPopup="issue['openPriority'] = $event" />
              </BDropdown>
              <div @click="togglePriorityDropdown(issue)" class="cursor-pointer"></div>
            </div>
            <div class="titleWrapper" :style="{
    width: issue['labelsArray'].length <= 2 ? '35%' : '35%',
  }">
              <router-link class="issueLink" :to="`/${workspaceSlug.slug}/issue/${issue.identifier}`">
                <span style="cursor: pointer">{{ issue.title }}</span>
              </router-link>
            </div>
            <div class="rightWrapper" style="width: 30%">
              <div class="issuesListAssigneeWrapper" style="float: right">
                <BDropdown v-model="issue['openAssignee']" variant="link" toggle-class="text-decoration-none" no-caret>
                  <template #button-content>
                    <DisplayAvatar :avatar="issue.assigneeUser == null
    ? null
    : issue.assigneeUser['avatar']
    " />
                  </template>
                  <AssigneeDropdown v-if="issue.team_id != undefined" :teamId="issue.team_id" class="issuesAssignee"
                    @selected:item="editedTaskAssignee(issue, $event)" />
                </BDropdown>
              </div>
              <div class="time-wrapper" style="
                    float: right;
                    padding: 0 5px;
                    line-height: 25px;
                    font-size: 12px;
                  ">
                {{ issue.created_at }}
              </div>
              <div class="labelWrapper d-none d-md-inline" v-if="issue['labelsArray'].length <= 2" style="float: right">
                <div v-for="(label, indexlabel) in issue['labelsArray']" :key="indexlabel" :style="{
    background: `rgba(${label.colorArray[0]},${label.colorArray[1]},${label.colorArray[2]}, 0.3)`,
    'border-radius': '20px',
    padding: '5px 10px',
    'font-size': '10px',
    'margin-right': '5px',
    display: 'inline-block',
    'margin-bottom': '5px',
  }">
                  <span :style="{
    background: label.color,
    width: '8px',
    height: '8px',
    'border-radius': '10px',
    display: 'inline-block',
  }"></span>
                  {{ label.label }}
                </div>
              </div>
              <div style="float: right" class="labelWrapper d-none d-md-inline" @click="toggleLabelDropdown(issue)"
                v-if="issue['labelsArray'].length > 2">
                <div :style="{
    background: `rgba(${issue['labelsArray'][0]['colorArray'][0]},${issue['labelsArray'][0]['colorArray'][1]},${issue['labelsArray'][0]['colorArray'][2]}, 0.3)`,
    'border-radius': '20px',
    padding: '5px 10px',
    'font-size': '10px',
    'margin-right': '5px',
    display: 'inline-block',
    'margin-bottom': '5px',
  }">
                  <span :style="{
    background: issue['labelsArray'][0]['color'],
    width: '8px',
    height: '8px',
    'border-radius': '10px',
    display: 'inline-block',
  }"></span>
                  {{ issue["labelsArray"].length + " labels" }}
                </div>
                <div class="dropdown-menu position-bottom" style="min-width: 120px !important; width: 120px !important"
                  :class="{ show: issue['openLabels'] }">
                  <ul class="mb-0">
                    <li v-for="(itemLabel, index) in issue['labelsArray']" :key="index">
                      <div class="row">
                        <div :style="{
    background: `rgba(${itemLabel.colorArray[0]},${itemLabel.colorArray[1]},${itemLabel.colorArray[2]}, 0.3)`,
    'border-radius': '20px',
    padding: '5px 10px',
    'font-size': '10px',
    'margin-right': '5px',
    display: 'inline-block',
    'margin-bottom': '5px',
  }">
                          <span :style="{
    background: itemLabel.color,
    width: '8px',
    height: '8px',
    'border-radius': '10px',
    display: 'inline-block',
  }"></span>
                          {{ itemLabel.label }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showPopup">
    <NewIssue @update:showPopup="showPopup = $event" :statusSelectedFromProps="selectedStatusForNewIssue" />
  </div>
  <div class="bottomControls" v-if="selectedIssuesArray.length > 0">
    <div class="contents">
      <span class="counter"> {{ selectedIssuesArray.length }} selected </span>
      <span class="counter no-left-border">
        <i class="fa fa-close" @click="selectedIssuesArray = []"></i>
      </span>
      <BDropdown dropup variant="link" toggle-class="text-decoration-none" class="bulkDropdowns" no-caret>
        <template #button-content>
          <span class="button"> Change Status </span>
        </template>
        <div class="statusesUl">
          <a v-for="(item, index) in statuses" @click="updateBulkTasksStatus(item)" :key="index" class="block">
            <span v-html="item.icon"></span> {{ item.label }}
          </a>
        </div>
      </BDropdown>
      <BDropdown dropup variant="link" toggle-class="text-decoration-none" class="bulkDropdowns" no-caret>
        <template #button-content>
          <span class="button"> Change Priority </span>
        </template>
        <div class="statusesUl">
          <a v-for="(item, index) in priority" @click="updateBulkTasksPriority(item)" :key="index" class="block">
            <span v-html="item.icon"></span> {{ item.label }}
          </a>
        </div>
      </BDropdown>

      <BDropdown dropup variant="link" toggle-class="text-decoration-none" class="bulkDropdowns" no-caret>
        <template #button-content>
          <span class="button"> Other </span>
        </template>
        <div class="statusesUl">
          <a class="block" @click="updateBulkTasksAssigneToMe">Assign to me </a>
          <a class="block" @click="showAssigneeForBulkAction = true">Assign to</a>
          <a class="block" @click="showLabelsForBulkAction = true">Change Labels</a>
          <a class="block">Change Subscribers</a>
          <a class="block">Set Due Date</a>
        </div>
      </BDropdown>
      <BDropdown dropup variant="link" v-model="showAssigneeForBulkAction" toggle-class="text-decoration-none"
        class="bulkDropdowns" no-caret>
        <AssigneeDropdown @selected:item="updateBulkTasksAssigneToUser($event)" />
      </BDropdown>
      <BDropdown dropup v-model="showLabelsForBulkAction" variant="link" toggle-class="text-decoration-none"
        class="bulkDropdowns" no-caret>
        <LabelsDropdown @selected:label="editedBulkTaskLabels($event)" style="width: 100%" :selectedLabels="selectedLabels != null ? selectedLabels : []
    " />
      </BDropdown>
      <BDropdown dropup v-model="showLabelsForBulkAction" variant="link" toggle-class="text-decoration-none"
        class="bulkDropdowns" no-caret>
        <SubscribersDropdown :teamMembers="teamMembers" :issueSubscribers="[]"
          @selected:member="saveIssueSubscriber($event)" />
      </BDropdown>
    </div>
  </div>
</template>

<style>
.kanban_container {
  overflow: scroll;
  height: 90vh;
}

.kanban_container .dndrop-container.vertical {
  height: 100vh;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card {
  width: 100%;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  height: 146px;
  background: #FFFFFF;
  padding: 10px;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper {
  margin-top: 10px;
  box-shadow: 0px 0px 8px 0px #0000001A;
  border-radius: 8px;
  overflow: inherit;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card span.issueNumber {
  color: #3D4046;
  font-size: 12px;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card span.projectTitle {
  color: #7B8497;
  padding-left: 5px;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card p {
  font-size: 16px;
  font-weight: 500;
  color: #3D4046;

}

.subtasks {
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  width: 51px;
  height: 24px;
  float: left;
  text-align: center;
  margin-left: 5px;
}

.subtasks span {
  color: #7B8497;
  font-size: 12px !important;

}

.calendaricon {
  margin: 0 10px;
  float: left;
}

.linefour {
  margin-top: 10px;
}

.linefour .site {
  float: left;
  border: 1px solid #EAEAEA;
  width: 90px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
}

.linefour .flag {
  float: left;
  border: 1px solid #EAEAEA;
  width: 48px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
  margin: 0 5px;
}

.linefour .light {
  float: left;
  border: 1px solid #EAEAEA;
  width: 39px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
}

.linefour .block {
  float: left;
  border: 1px solid #EAEAEA;
  width: 28px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
  margin-left: 5px;
}

.linefour span {
  color: #7B8497;
  font-size: 12px !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.kanban_container .card-container {
  width: 260px;
  margin-right: 15px;
}

.kanban_container .card-column-header {
  width: 100%;
  background: #EAEAEA;
  border-radius: 8px;
  padding: 0 10px;
  line-height: 40px;
}

.kanban_container .card-column-header .statusLabel {
  padding-left: 8px;
  color: #2A2C32;
  font-size: 14px;
  font-weight: 500 !important;
}

.kanban_container .card-column-header .issueCounter {
  padding-left: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #7B8497;

}
</style>