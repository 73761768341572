<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M9.00004 7.99999C9.00004 10.12 7.28671 11.8333 5.16671 11.8333C3.04671 11.8333 1.33337 10.12 1.33337 7.99999C1.33337 5.87999 3.04671 4.16666 5.16671 4.16666"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66663 8C6.66663 5.79333 8.45996 4 10.6666 4C12.8733 4 14.6666 5.79333 14.6666 8C14.6666 10.2067 12.8733 12 10.6666 12"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
