<template>
  <div class="items mentionsList">
    <div class="wrapper" v-if="itemsWithHeadings['members'] != undefined && itemsWithHeadings['members'].length">
      <h6>Users</h6>
      <button class="item" v-for="(item, index) in itemsWithHeadings['members']" :key="index" @click="selectItem(item)">
        <DisplayAvatar :avatar="item['avatar'] != null ? item['avatar'] : null" /> {{ item["name"] }}
      </button>
    </div>

    <div class="wrapper" v-if="itemsWithHeadings['projects'] != undefined && itemsWithHeadings['projects'].length">
      <h6>Projects</h6>
      <button class="item" v-for="(item, index) in itemsWithHeadings['projects']" :key="index"
        @click="selectItem(item)">
        <CupIcon /> &nbsp;
        {{ item["name"] }}
      </button>
    </div>

    <div class="wrapper" v-if="itemsWithHeadings['issues'] != undefined && itemsWithHeadings['issues'].length">
      <h6>Issues</h6>
      <button class="item" v-for="(item, index) in itemsWithHeadings['issues']" :key="index" @click="selectItem(item)">
        <ClockIcon style="width: 16px; height: 16px" v-if="item.status == null" />
        <BacklogIcon v-if="item.status != null && item.status.label == 'backlog'" :fill="item.status.color" />
        <NotStartedIcon v-if="item.status != null && item.status.label == 'notstarted'" :fill="item.status.color" />
        <StartedIcon v-if="item.status != null && item.status.label == 'started'" :fill="item.status.color" />
        <TestingIcon v-if="item.status != null && item.status.label == 'testing'" :fill="item.status.color" />
        <PendingIcon v-if="item.status != null && item.status.label == 'pending'" :fill="item.status.color" />
        <CompletedIcon v-if="item.status != null && item.status.label == 'completed'" :fill="item.status.color" />
        <CancelledIcon v-if="item.status != null && item.status.label == 'cancelled'" :fill="item.status.color" />
        &nbsp;
        {{ item["name"] }}
      </button>
    </div>
  </div>
</template>

<script>
import DisplayAvatar from '../Pages/Profile/DisplayAvatar.vue';
import ClockIcon from '../Icons/ClockIcon.vue';
import BacklogIcon from '../Icons/BacklogIcon.vue';
import NotStartedIcon from '../Icons/NotStartedIcon.vue';
import StartedIcon from '../Icons/StartedIcon.vue';
import TestingIcon from '../Icons/TestingIcon.vue';
import PendingIcon from '../Icons/PendingIcon.vue';
import CompletedIcon from '../Icons/CompletedIcon.vue';
import CancelledIcon from '../Icons/CancelledIcon.vue';
import CupIcon from '../Icons/CupIcon.vue';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    command: {
      type: Function,
      required: true,
    },
  },
  components: {
    DisplayAvatar,
    BacklogIcon,
    ClockIcon,
    NotStartedIcon,
    StartedIcon,
    TestingIcon,
    PendingIcon,
    CompletedIcon,
    CancelledIcon,
    CupIcon
  },

  data() {
    return {
      itemsWithHeadings: []
    }
  },

  watch: {
    items() {
      this.reCompileItems();
    },
  },

  mounted: function () {
    this.reCompileItems();
  },

  methods: {
    reCompileItems() {
      let finalArray = [];
      for (let item of this.items) {
        if (finalArray[item.type] == undefined) {
          finalArray[item.type] = [];
        }
        finalArray[item.type].push(item);
      }
      console.log(finalArray, "Final  Array");
      this.itemsWithHeadings = finalArray;
    },
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    onMounted() {
      console.log(this.items, "Items");
    },

    upHandler() {
      //this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
    },

    downHandler() {
      //this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },

    enterHandler() {
      //this.selectItem(this.selectedIndex)
    },

    selectItem(item) {
      //const item = this.items[index]
      if (item) {
        this.command({ id: JSON.stringify(item) })
      }
    },
  },
}
</script>

<style lang="scss">
.items {
  padding: 0.2rem;
  position: relative;
  border-radius: 0.5rem;
  background: #FFF;
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.9rem;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(0, 0, 0, 0.1),
  ;
  min-width: 250px;
}

.mentionsList {
  max-height: 200px !important;
  height: 200px !important;
  overflow: auto !important;
}

.item {
  display: block;
  margin: 0;
  width: 100%;
  text-align: left;
  background: transparent;
  border-radius: 0.4rem;
  border: 1px solid transparent;
  padding: 0.2rem 0.4rem;

  &.is-selected {
    border-color: #000;
  }
}
</style>