<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 3.66998V20.33L11.2 21.24C10.09 22.5 9.18001 22.16 9.18001 20.48V13.28H6.09001C4.69001 13.28 4.30001 12.42 5.23001 11.37L12 3.66998Z"
      fill="#F96332"
    />
    <path
      opacity="0.4"
      d="M18.77 12.63L12 20.33V3.67002L12.8 2.76002C13.91 1.50002 14.82 1.84002 14.82 3.52002V10.72H17.91C19.31 10.72 19.7 11.58 18.77 12.63Z"
      fill="#F96332"
    />
  </svg>
</template>
