<script setup>
import { useRouter } from "vue-router";
import DownArrowIcon from './Icons/DownArrowIcon.vue';
var router = useRouter();
function goBack() {
  router.go(-1);
}
// defineProps(["heading", "breadCrumbs", "notShowBorder"]);
defineProps({
  heading: {
    type: String,
    default: ''
  },
  breadCrumbs: {
    type: String,
    default: ''
  },
  showBorder: {
    type: Boolean,
    default: true,
  },
  backbutton: {
    type: Boolean,
    default: false,
  },
  widthHalf: {
    type: Boolean,
    default: false,
  },
  paddingtrue: {
    type: Boolean,
    default: true,
  }
})
</script>

<template>
  <div class="w-100 app-header" :class="{
    'w-50': widthHalf, 
    'px-3': paddingtrue}">
    <div
      :class="{ 'border-bottom': showBorder }"
      class="d-flex align-items-center pt-3 app-header-inner"
      style="padding-bottom: 32px; border-color: #eaeaea"
    >
    <span class="me-3" v-if="backbutton" @click="goBack">
      <DownArrowIcon style="
      width: 25px;
    height: 25px;
    transform: rotate(90deg);
    border: 1px solid #000;
    padding: 5px;
    border-radius: 20px;"/>
    </span>
      <h2 class="m-0">{{ heading }}</h2>
      <div
        v-if="breadCrumbs"
        style="
          width: 1px;
          height: 32px;
          background-color: #2a2c32;
          margin: 0px 10px;
        "
      ></div>
      <p
        v-if="breadCrumbs"
        style="margin: 0; color: #7b8497; font-size: 20px; font-weight: 500"
      >
        {{ breadCrumbs }}
      </p>
    </div>
  </div>
</template>

<style lang="scss">
.backbutton svg path {
    fill: #000;
    stroke: #000;
}
</style>
