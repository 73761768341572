<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 6V9.33333"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00004 14.2734H3.96004C1.6467 14.2734 0.680037 12.6201 1.80004 10.6001L3.88004 6.85339L5.84004 3.33339C7.0267 1.19339 8.97337 1.19339 10.16 3.33339L12.12 6.86006L14.2 10.6067C15.32 12.6267 14.3467 14.2801 12.04 14.2801H8.00004V14.2734Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.99634 11.3334H8.00233"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
