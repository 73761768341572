<script setup>
import AppHeader from "@/components/AppHeader.vue";
import ClockIcon from "@/components/Icons/ClockIcon.vue";
import CampbellIcon from "@/components/Icons/CampbellIcon.vue";
import CheckBoxInput from "@/components/CheckBoxInput.vue";
import DeleteIcon from "@/components/Icons/DeleteIcon";
import NewProject from "@/components/Pages/Project/NewProject.vue";
import EditProjectPopup from "@/components/Pages/Project/EditProjectPopup.vue";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import api from "@/api";

const toast = useToast();
var route = useRoute();
const data = ref([]);
const showPopup = ref(false);
const showOverlay = ref(false);
const showEditPopup = ref(false);
let projectToDelete = ref(null);
let filterAllClass = ref("active");
let filterActiveClass = ref("noactive");
let filterClosedClass = ref("noactive");
let filterBacklogClass = ref("noactive");
let selectedFilter = ref("All");

onMounted(() => {
  const teamId = route.params.id;
  fetchData(teamId);
});

function onDeleteClick(item) {
  projectToDelete.value = item;
}

function onDeleteClickFromModal() {
  const team_id = route.params.id;
  api
    .put(`teams/${team_id}/projects/${projectToDelete.value.id}`, {
      status: "archive",
    })
    .then((response) => {
      let btn = document.getElementById("workFlowModalCancelBtn");
      btn.click();
      fetchData(team_id);
      toast("Your project has been archived.", {
        position: "top-right",
        timeout: 4025,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: DeleteIcon,
        rtl: false,
      });
    });
}

function editProject(index) {
  showEditPopup.value = true;
  localStorage.setItem("editProjectId", data.value[index].id);
}

function fetchFilteredData(filter) {
  const teamId = route.params.id;
  fetchData(teamId, filter);
  if (filter == "all") {
    selectedFilter.value = "All";
    filterAllClass.value = "active";
    filterActiveClass.value = "noactive";
    filterClosedClass.value = "noactive";
    filterBacklogClass.value = "noactive";
  } else if (filter == "inprogress") {
    selectedFilter.value = "Active";
    filterAllClass.value = "noactive";
    filterActiveClass.value = "active";
    filterClosedClass.value = "noactive";
    filterBacklogClass.value = "noactive";
  } else if (filter == "backlog") {
    selectedFilter.value = "Backlog";
    filterAllClass.value = "noactive";
    filterActiveClass.value = "noactive";
    filterClosedClass.value = "noactive";
    filterBacklogClass.value = "active";
  } else if (filter == "complete") {
    selectedFilter.value = "Closed";
    filterAllClass.value = "noactive";
    filterActiveClass.value = "noactive";
    filterClosedClass.value = "active";
    filterBacklogClass.value = "noactive";
  }
}

function fetchData(teamId, filter = "all") {
  if (filter == "all") {
    selectedFilter.value = "All";
    filterAllClass.value = "active";
    filterActiveClass.value = "noactive";
    filterClosedClass.value = "noactive";
    filterBacklogClass.value = "noactive";
  }
  api
    .get(`teams/${teamId}/projects?filter=${filter}`)
    .then((response) => {
      if (response.status === 200) {
        data.value = response.data;
      }
    })
    .catch((error) => {
      if (error) {
        console.error(error);
        return false;
      }
    });
}
</script>

<template>
  <AppHeader heading="Projects" :breadCrumbs="selectedFilter" />
  <div class="px-3">
    <div class="py-4">
      <div
        class="d-flex flex-wrap justify-content-around justify-content-md-between mb-5"
      >
        <div
          class="align-items-center component2 d-flex flex-grow-1 flex-md-grow-0 me-sm-4 pe-md-5 mb-2 mb-sm-0"
        >
          <div :class="filterAllClass" @click="fetchFilteredData('all')">
            <strong>All</strong>
          </div>
          <div
            :class="filterBacklogClass"
            @click="fetchFilteredData('backlog')"
          >
            <strong>Backlog</strong>
          </div>
          <div
            :class="filterActiveClass"
            @click="fetchFilteredData('inprogress')"
          >
            <strong>Active</strong>
          </div>
          <div
            :class="filterClosedClass"
            @click="fetchFilteredData('complete')"
          >
            <strong>Closed</strong>
          </div>
        </div>
        <div class="">
          <button
            class="custom-primary-btn"
            type="button"
            @click="showPopup = true"
          >
            <strong>New Project</strong>
          </button>
        </div>
      </div>

      <!-- <router-link to="edit-project" class="text-decoration-none">
          <button class="custom-primary-btn" type="button">
            <strong>edit project</strong>
          </button>
        </router-link> -->

      <!-- popup -->
      <div v-if="showPopup">
        <NewProject
          :fetchData="fetchData"
          @update:showPopup="showPopup = $event"
        />
      </div>
      <!-- end popup -->

      <!-- edit popup -->
      <div v-if="showEditPopup">
        <EditProjectPopup
          :fetchData="fetchData"
          @update:showEditPopup="showEditPopup = $event"
        />
      </div>
      <!-- end popup -->

      <div class="row">
        <div
          class="col-md-12"
          style="
            padding: 0;
            border-radius: 10px 10px 0 0;
            height: 100vh;
            border-left: 1px solid #eaeaea;
            border-right: 1px solid #eaeaea;
          "
        >
          <div class="rectangle">
            <strong>Projects List</strong>
          </div>
          <div v-if="data.length == 0" class="noprojects">
            <h3 v-if="selectedFilter == 'All'">
              There are no projects in the current team
            </h3>
            <h3 v-if="selectedFilter == 'Backlog'">
              There are no projects in Backlog
            </h3>
            <h3 v-if="selectedFilter == 'Active'">
              There are no Active projects
            </h3>
            <h3 v-if="selectedFilter == 'Closed'">
              There are no Closed projects
            </h3>
            <button
              class="custom-primary-btn"
              type="button"
              @click="showPopup = true"
            >
              <strong>New Project</strong>
            </button>
          </div>
          <div v-if="data.length > 0" style="">
            <div style="height: 25px"></div>

            <div
              class="project d-flex justify-content-between align-items-center text-center cursor-pointer"
              v-for="(project, index) in data"
              :key="index"
            >
              <div>
                <div class="iconBackground position-relitive text-center">
                  <CampbellIcon />
                </div>
                <a
                  href="/aleem-workspace/issues"
                  style="color: #2a2c32; font-size: 14px; margin-left: 5px"
                  >{{ project.name }}</a
                >
              </div>

              <!-- <div class=""> -->
              <div class="d-flex justify-content-end" style="gap: 12px">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.1 11.2747C10.0833 11.2747 10.0583 11.2747 10.0417 11.2747C10.0167 11.2747 9.98333 11.2747 9.95833 11.2747C8.06666 11.2164 6.64999 9.74141 6.64999 7.92474C6.64999 6.07474 8.15833 4.56641 10.0083 4.56641C11.8583 4.56641 13.3667 6.07474 13.3667 7.92474C13.3583 9.74974 11.9333 11.2164 10.125 11.2747C10.1083 11.2747 10.1083 11.2747 10.1 11.2747ZM9.99999 5.80807C8.83333 5.80807 7.89166 6.75807 7.89166 7.91641C7.89166 9.05807 8.78333 9.98307 9.91666 10.0247C9.94166 10.0164 10.025 10.0164 10.1083 10.0247C11.225 9.96641 12.1 9.04974 12.1083 7.91641C12.1083 6.75807 11.1667 5.80807 9.99999 5.80807Z"
                      fill="#7B8497"
                    />
                    <path
                      d="M10 18.9586C7.75833 18.9586 5.61666 18.1253 3.95833 16.6086C3.80833 16.4753 3.74166 16.2753 3.75833 16.0836C3.86666 15.0919 4.48333 14.1669 5.50833 13.4836C7.99166 11.8336 12.0167 11.8336 14.4917 13.4836C15.5167 14.1753 16.1333 15.0919 16.2417 16.0836C16.2667 16.2836 16.1917 16.4753 16.0417 16.6086C14.3833 18.1253 12.2417 18.9586 10 18.9586ZM5.06666 15.9169C6.45 17.0753 8.19166 17.7086 10 17.7086C11.8083 17.7086 13.55 17.0753 14.9333 15.9169C14.7833 15.4086 14.3833 14.9169 13.7917 14.5169C11.7417 13.1503 8.26666 13.1503 6.2 14.5169C5.60833 14.9169 5.21666 15.4086 5.06666 15.9169Z"
                      fill="#7B8497"
                    />
                    <path
                      d="M9.99999 18.9577C5.05832 18.9577 1.04166 14.941 1.04166 9.99935C1.04166 5.05768 5.05832 1.04102 9.99999 1.04102C14.9417 1.04102 18.9583 5.05768 18.9583 9.99935C18.9583 14.941 14.9417 18.9577 9.99999 18.9577ZM9.99999 2.29102C5.74999 2.29102 2.29166 5.74935 2.29166 9.99935C2.29166 14.2493 5.74999 17.7077 9.99999 17.7077C14.25 17.7077 17.7083 14.2493 17.7083 9.99935C17.7083 5.74935 14.25 2.29102 9.99999 2.29102Z"
                      fill="#7B8497"
                    />
                  </svg>
                </div>
                <div>
                  <ClockIcon></ClockIcon>
                </div>
                <div>
                  <a
                    class="dropdown-item fs-14px"
                    data-bs-toggle="modal"
                    @click="onDeleteClick(project)"
                    data-bs-target="#workflowdeleteModal"
                  >
                    <DeleteIcon></DeleteIcon>
                  </a>
                </div>
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
        
        <!-- <div class="col-md-8 p-0">
            <div class="rectangle d-flex text-center">
              <div class="col-md-3">
                <strong>May</strong>
              </div>
              <div class="col-md-3">
                <strong>June</strong>
              </div>
              <div class="col-md-3">
                <strong>July</strong>
              </div>
              <div class="col-md-3">
                <strong>Augest</strong>
              </div>
            </div>

            <div style="height: 100vh; border-left:1px solid #EAEAEA; border-right:1px solid #EAEAEA">
              <div class="d-flex">
                <div class="col-md-3" style="height: 100vh; border-right:1px solid #EAEAEA"></div>
                <div class="col-md-3" style="height: 100vh; border-right:1px solid #EAEAEA"></div>
                <div class="col-md-3" style="height: 100vh; border-right:1px solid #EAEAEA"> </div>
                <div class="col-md-3" style="height: 100vh; border-right:1px solid #EAEAEA"></div>
                </div>
            </div>
          </div> -->
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="workflowdeleteModal"
    tabindex="-1"
    aria-labelledby="workflowdeleteModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Alert</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Are you sure you want to archive this project?
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="custom-secondary-btn"
            data-bs-dismiss="modal"
            id="workFlowModalCancelBtn"
          >
            Cancel
          </button>
          <button
            type="button"
            class="custom-primary-btn"
            @click="onDeleteClickFromModal"
          >
            Archive
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.component2 {
  background-color: #eaeaea;
  padding: 4px 16px;
  gap: 22px;
  border-radius: 6px;
  cursor: pointer !important;
}

.active {
  border-radius: 6px;
  background: #f96332;
  padding: 5px 10px;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer !important;
  /* height: 32px; */
}

.noActive {
  padding: 5px 10px;
  color: #3d4046;
  font-size: 12px;
  cursor: pointer !important;
}

.component6 {
  background-color: #eaeaea;
  border-radius: 8px;
  padding: 6px 24px;
  border: 1px solid #c4c4ca;
  box-shadow: none !important;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.rectangle {
  background: #eaeaea;
  border-radius: 8px 8px 0px 0px;
  height: 78px;
  padding: 15px;
  line-height: 16px;
  font-size: 16px;
  color: #3d4046;
}

.project {
  background-color: #f6f6f6;
  height: 56px;
  padding: 15px;
}

.iconBackground {
  width: 24px;
  height: 22px;
  border-radius: 8px;
  background: linear-gradient(to bottom, #ffffff, #d0d5dd);
  display: inline-block;
  vertical-align: middle;
  margin: 0px;
}

.dates {
  font-size: 12px;
  gap: 7px;
  color: #7b8497;
  margin-top: 10px;
  text-align: center;
}

.iconBorder {
  border: 1px solid #c4c4ca;
  border-radius: 8px;
  padding: 6px 13px;
}

.cupicon {
  width: 32px;
  height: 32px;
  background: linear-gradient(to bottom, #ffffff, #d0d5dd);
  border-radius: 8px;
  display: inline-block;
  vertical-align: middle;
  margin: 0px;
  padding: 5px 0px;
}

.iconContainer {
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  /* Adjust the right position as needed */
  pointer-events: none;
  /* Ensure the icon doesn't interfere with input interactions */
}

.milestones {
  width: 299px;
  height: 48px;
  border-radius: 12px;
  background: #f6f6f6;
  padding: 10px;
}
</style>
