<template>
    <node-view-wrapper class="code-block">
        <BDropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
                <div class="labelButton" style="cursor: pointer">
                    {{ selectedLanguage == null ? 'Auto' : returnLanguageName(selectedLanguage) }}
                </div>
            </template>
            <ul class="language-dropdown">
                <li @click="selectedLanguage = null">Auto</li>
                <li @click.stop="">—</li>
                <li v-for="(language, index) in languages" :value="language" :key="index"
                    @click="selectedLanguage = language">
                    <span>
                        {{ returnLanguageName(language) }}
                    </span>
                    <span>
                        <i v-if="selectedLanguage == language" style="color: #000; top:0; right: 0.5rem;" class="fa-solid fa-check"></i>
                    </span>
                </li>
            </ul>
        </BDropdown>
        <i class="fa-regular fa-copy" @click="copyCode"></i>
        <pre><code><node-view-content /></code></pre>
    </node-view-wrapper>
</template>

<script>
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'

export default {
    components: {
        NodeViewWrapper,
        NodeViewContent,
    },

    props: nodeViewProps,

    data() {
        return {
            //languages: this.extension.options.lowlight.listLanguages(),
            languages: ['golo', 'javascript', 'json', 'typescript', 'plainttext', 'python'],
        }
    },

    computed: {
        selectedLanguage: {
            get() {
                return this.node.attrs.language
            },
            set(language) {
                this.updateAttributes({ language })
            },
        },
    },
    methods: {
        copyCode: () => {
            console.log("Copied");
        },
        returnLanguageName: (name) => {
            const languageNameArray = {
                'golo': "Go",
                'javascript': "JavaScript",
                'json': "JSON",
                'typescript': "TypeScript",
                'plainttext': "PlainText",
                'python': "Python"
            };
            return languageNameArray[name] != undefined ? languageNameArray[name] : name.charAt(0).toUpperCase() + name.slice(1);
        }
    }
}
</script>

<style lang="scss">
.tiptap {

    .code-block {
        .language-dropdown {
            padding: 1rem !important;

            li {
                cursor: pointer;
                position: relative;
            }
        }

        position: relative;

        select {
            position: absolute;
            background-color: #FFFFFF !important;
            right: 2rem;
            top: 0.5rem;
            border: none;
            box-shadow: none;
            border-radius: 8px;
            padding: 0 0.5rem;
        }

        i {
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            color: #FFFFFF;
            font-size: 1.1rem;
            cursor: pointer;
        }

        .btn-group {
            position: absolute;
            right: 2rem;
            top: 0.3rem;

            button {
                background-color: #fff;
                padding: 0 0.5rem;
                border-radius: 8px;
                color: #000;
                font-size: 1rem;
            }
        }
    }
}
</style>