<script setup>
import Backlog from "@/components/Icons/BacklogIcon.vue";
import NotStarted from "@/components/Icons/NotStartedIcon.vue";
import Started from "@/components/Icons/StartedIcon.vue";
import Testing from "@/components/Icons/TestingIcon.vue";
import Pending from "@/components/Icons/PendingIcon.vue";
import Completed from "@/components/Icons/CompletedIcon.vue";
import Cancelled from "@/components/Icons/CancelledIcon.vue";
import ClockIcon from "@/components/Icons/ClockIcon.vue";
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { useToast } from "vue-toastification";
import '@vuepic/vue-datepicker/dist/main.css'
import { db, defaultDatabase, initiateDatabase } from '@/localdatabase/dbConfig';
const props = defineProps(['issueId', 'teamId', 'workflowId'])
const route = useRoute()
const emits = defineEmits(['update:showPopup', 'selected:item']);
const selectedPriorityDropdown = ref(null);
const name = ref('');
const shownPopup = ref(false);
const justMounted = ref(0);
const statuses = ref([]);
onMounted(() => {
  shownPopup.value = true;
  fetchStatuses();
});

watch(() => props.workflowId, (first, second) => {
  fetchStatuses();
});

function fetchStatuses() {
  db.workflow_statuses.where({ workflow_id: parseInt(props.workflowId) }).sortBy('order').then((response) => {
    statuses.value = response;
  })
    .catch((error) => {
      if (error) {
        return false;
      }
    });
}


function selectStatus(priority) {
  emits('selected:item', priority);
  closePopup();
}

function closePopup() {
  emits('update:showPopup', false);
}

function onClickOutside() {
  if (justMounted.value == 0) {
    justMounted.value = justMounted.value + 1;
    return;
  }
  closePopup();
}
</script>

<template>
  <div class="" style="min-width:180px;">
    <ul class="mb-0">
      <li v-for="(item, index) in statuses" :key="index" style="cursor: pointer;">
        <label v-bind:for="item.value" @click="selectStatus(item)">
          <div class="row">
            <div class="dropdown-element-exclude d-flex col align-items-center" style="gap: 8px;">
              <ClockIcon style="width: 16px; height: 16px" v-if="item.id == 0" />
              <Backlog v-if="item.label == 'backlog'" :fill="item.color" />
              <NotStarted v-if="item.label == 'notstarted'" :fill="item.color" />
              <Started v-if="item.label == 'started'" :fill="item.color" />
              <Testing v-if="item.label == 'testing'" :fill="item.color" />
              <Pending v-if="item.label == 'pending'" :fill="item.color" />
              <Completed v-if="item.label == 'completed'" :fill="item.color" />
              <Cancelled v-if="item.label == 'cancelled'" :fill="item.color" />
              {{ item.name }}
            </div>
          </div>
        </label>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.gray-color {
  color: #7B8497;
  font-size: 14px;
}

.mr-1 {
  margin-right: 1rem;
}

.subtask-button {
  width: 80%;
  float: right;
}

.white-btn {
  background: #FFF;
  font-weight: normal;
  color: #000;
  margin-left: 1rem;
}

.white-btn:hover {
  color: #000;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  width: 268px;
  min-height: 200px;
  padding: 10px 14px;
  border-radius: 12px;
  border: 1px solid #C4C4CA;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.dropdown-wrapper .subtask {
  top: -1000% !important;
  left: -400% !important;
  min-height: 150px !important;
}

/* Add the 'show' class when isDropdownOpen is true */
.dropdown-menu.show {
  display: block;
}

.position-top {
  top: 110%;
  max-height: 351px;
}

.position-bottom {
  bottom: 110%;
  max-height: 392px;
}

.dropdown-menu input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: none;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
}

.dropdown-menu ul li label {
  display: block;
  margin-bottom: 5px;
}

/* Style the checkbox however you like */
.dropdown-menu ul li input[type="checkbox"] {
  margin-right: 5px;
}

.selected-leader {
  background: #EAEAEA;
}

.popup {
  width: 50% !important;
}

.fa-close {
  position: absolute;
  right: 3rem;
  top: 2rem;
  background: #999;
  color: #FFF;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}

.more {
  background-color: #EAEAEA;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  min-width: 60px;
}
</style>