<script setup>
import AppHeader from "@/components/AppHeader.vue";
import InputField from "@/components/Fields/InputField";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { Switch } from "@headlessui/vue";
import ShareIcon from "@/components/Icons/ShareIcon.vue";
import api from "@/api";
import Danger from "@/components/ToastIcons/ErrorIcon.vue";
import SuccessIcon from "@/components/ToastIcons/SuccessIcon.vue";
import { db } from "@/localdatabase/dbConfig";
import { onMounted, ref } from "vue";
import NewField from "@/components/Icons/NewField.vue";
import SelectDropdown from "@/components/Fields/SelectDropdown.vue";
const emits = defineEmits(['close:popup', 'setting:selected', 'saved:field']);
var router = useRouter();
const selectedMember = ref(null);
let teams_id = ref(null);
let currentTeam = ref(null);
let data = ref(null);
const toast = useToast();
const copyFromOtherTeam = ref(false);
const fieldType = ref(null);
const teams = ref([]);
const fieldNameText = ref("");
const fieldNameDate = ref("");
onMounted(async () => {
  const user_id = localStorage.getItem("workspace")
  data.value = JSON.parse(user_id).user_id;
  teams_id.value = router.currentRoute.value.params.id;
  await fetchTeams();
});

function closePopup() {
  emits('close:popup', false);
}

async function fetchTeams() {
  const teamsTemp = await db.teams.toArray();
  for (let team of teamsTemp) {
    teams.value.push({
      id: team.id,
      label: team.name
    });
  }
}

function saveField() {
  api
    .post(`teams/${teams_id.value}/fields`, {
      name: fieldType.value == 'text' ? fieldNameText.value : fieldNameDate.value,
      description: "",
      type: fieldType.value,
      team_id: teams_id.value,
      options: []
    })
    .then((response) => {
      if (response.status == 201) {
        toast("Field is saved successfully", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: SuccessIcon,
          rtl: false,
        });
        closePopup();
        emits('saved:field', response.data);
      }
      else {
        toast("Field cannot be saved. Please try again later", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      }
    });
}

function goToNextStep() {
  if (fieldType.value == null) {
    toast("Please select any option first", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
  }
  else {
    if (copyFromOtherTeam.value || fieldType.value == "text" || fieldType.value == 'date') {
      saveField();
    }
    else {
      emits('setting:selected', fieldType.value);
      closePopup();
    }
  }
}

function selectTeam(team) {

}
</script>
<template>
  <i class="fa fa-close custom-fa-close" style="text-align: center; padding: 0; top: 1rem; right: 1rem;"
    @click="closePopup"></i>
  <div class="row">
    <div class="col-md-10 mt-3 p-0">
      <h2>
        <NewField />
        New Field
      </h2>
    </div>
    <div class="col-md-2 mt-3 p-0" style="text-align: center;">
      <div class="iconBorder" style="width: 35px; padding:6px 5px;">
        <ShareIcon />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 p-0 mt-4">
      <span>Copy existing field from another team</span>
    </div>
    <div class="col-md-2 p-0 mt-4">
      <Switch v-model="copyFromOtherTeam" :style="copyFromOtherTeam
      ? 'background: #F96332;'
      : 'background: #EAEAEA;'
      " style="" class="custom-switch transition-colors duration-200 ease-in-out">
        <span class="sr-only">Copy existing field from another team</span>
        <span aria-hidden="true" :class="copyFromOtherTeam ? 'translate-x-9' : 'translate-x-0'
      " class="custom-transform custom-transition duration-200 ease-in-out"></span>
      </Switch>
    </div>
  </div>
  <div class="row" v-if="teams.length > 0 && copyFromOtherTeam">
    <div class="col-md-6 mt-3 p-0">
      <SelectDropdown :items="teams" :displayKey="'label'" :placeholder="'Choose Team'" :initialSelectedValue="null"
        @onSelectValue="selectTeam">
      </SelectDropdown>
    </div>
  </div>
  <div class="fields-wrapper" v-if="!copyFromOtherTeam">
    <div class="row">
      <div class="col-md-5 mt-3" style="align-content: center;">
        <input type="radio" name="fieldType" id="fieldTypeText" v-model="fieldType" value="text" />
        &nbsp;&nbsp;
        <label for="fieldTypeText">Short Text</label>
      </div>
      <div class="col-md-1 mt-3"></div>
      <div class="col-md-6 mt-3">
        <InputField v-model="fieldNameText" :placeholder="'Text Field'" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 mt-3" style="align-content: center;">
        <input type="radio" name="fieldType" id="fieldTypeDate" v-model="fieldType" value="date" />&nbsp;&nbsp;
        <label for="fieldTypeDate">Date Picker</label>
      </div>
      <div class="col-md-1 mt-3"></div>
      <div class="col-md-6 mt-3">
        <InputField v-model="fieldNameDate" :placeholder="'Date Picker'" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 mt-3">
        <input type="radio" name="fieldType" id="fieldTypeRadio" v-model="fieldType" value="radio" />&nbsp;&nbsp;
        <label for="fieldTypeRadio">Radio Option</label>
      </div>
      <div class="col-md-1 mt-3"></div>
      <div class="col-md-6 mt-3">
        <input type="radio" name="fieldType" id="fieldTypeDropdown" v-model="fieldType" value="dropdown" />&nbsp;&nbsp;
        <label for="fieldTypeDropdown">Dropdown (Single Select)</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-4">
      <button class="custom-primary-btn" type="button" style="float: right;" @click="goToNextStep">
        {{ copyFromOtherTeam || fieldType == 'date' || fieldType == 'text' ? 'Create' : 'Next' }}
      </button>
      <button type="button" class="custom-secondary-btn me-2" style="float: right;" @click="closePopup">
        Close
      </button>
    </div>
  </div>

</template>
<style lang="scss">
input[type=radio] {
  accent-color: #f96332;
}

.tab-wrapper {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(249, 99, 50, 0.1);

  .thead-view {
    .head-row-view {
      height: 60px;

      th {
        background-color: #eaeaea;
        font-weight: 500;
      }

      th:first-child {
        border-radius: 8px 0 0 0;
      }

      th:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  .tab-body {
    .row-view {
      height: 60px;
    }
  }
}
</style>