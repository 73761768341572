<template>
  <div style="height: 100vh; width: 100vw">
    <DataSyncer v-if="firstLoad == true" :showOverlay="showOverlay" @close:loading="SyncingDone($event)"
      @update:date="updateDate($event)" @clear:interval="stopTimer" />
    <router-view />
  </div>
</template>
<script setup>
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-svg-core";
import "@fortawesome/free-brands-svg-icons";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@/assets/css/style.css";
import "@/assets/css/custom-styles.scss";
import "@/components/Pages/StartingScreen/DataSyncer.vue";
import DataSyncer from "@/components/Pages/StartingScreen/DataSyncer.vue";
import { useRoute, useRouter } from "vue-router";
import { inject, watch, ref, onMounted } from "vue";
const isLoggedIn = inject("isLoggedIn");
const router = useRouter();
const firstLoad = ref(false);
const showOverlay = ref(false);
const timer = ref(null);
const isTokenExpired = ref(false);
const showLeftSide = inject("showLeftSide");
const showRightSide = inject("showRightSide");
const showSearch = inject("showSearch");
const showNewIssue = inject("showNewIssue");
const route = useRoute();
const workspaceSlug = ref("temp");
watch(isLoggedIn, (newValue, oldValue) => {
  if (newValue == true) {
    initializeTimer();
  } else {
    stopTimer();
  }
});
watch(route, (to, from) => {
  const token = localStorage.getItem("token");
  const workspace = JSON.parse(localStorage.getItem("workspace"));
  firstLoad.value =
    from.name == null &&
      (token != undefined || token != null) &&
      workspace != undefined
      ? true
      : false;
  showOverlay.value = firstLoad.value;
});
onMounted(function () {
  const token = localStorage.getItem("token");
  if (timer.value == null && token != null) {
    initializeTimer();
  }
  let workSpaceTemp = JSON.parse(localStorage.getItem("workspace"));
  workspaceSlug.value = workSpaceTemp.slug;
  document.addEventListener("keydown", handleShortcuts, false);
});
function handleShortcuts(event) {
  var inputs = ["input", "select", "textarea"];

  if (
    event.srcElement.classList.contains("ce-paragraph") ||
    event.srcElement.classList.contains("ql-editor") ||
    event.srcElement.classList.contains("tiptap") ||
    inputs.indexOf(event.srcElement.tagName.toLowerCase()) !== -1
  ) {
    return;
  }
  event.preventDefault();
  if (event.key == "[") {
    showLeftSide.value = !showLeftSide.value;
  }
  if (event.key == "]") {
    showRightSide.value = !showRightSide.value;
  }
  if (event.key == "I" && event.shiftKey == true) {
    router.push("/inbox");
  }
  if (event.key == "M" && event.shiftKey == true) {
    router.push(`/${workspaceSlug.value}/issues`);
  }
  if (event.key == "V" && event.shiftKey == true) {
    router.push(`/views`);
  }
  if (event.key == "T" && event.shiftKey == true) {
    router.push(`/teams`);
  }
  if (event.key == "S" && event.shiftKey == true) {
    showSearch.value = true;
  }
  if (event.key == "N" && event.shiftKey == true) {
    showNewIssue.value = true;
  }
  if (event.key == "P" && event.shiftKey == true) {
    router.push(`/user-profile`);
  }
  if (event.key == "X" && event.shiftKey == true) {
    router.push(`/user-preferences`);
  }
}
function initializeTimer() {
  timer.value = null;
  /*timer.value = setInterval(() => {
    showOverlay.value = false;
    firstLoad.value = true;
  }, 5000);*/
}

function stopTimer() {
  clearInterval(timer.value);
}
function SyncingDone(event) {
  firstLoad.value = event;
}
function updateDate(dateProvided) {
  localStorage.setItem("lastUpdateDate", dateProvided);
}
</script>
<script>
export default {
  name: "App",
};
</script>

<style>
h1 {

  font-size: 1.71429em !important;
}
</style>
