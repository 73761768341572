import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'

import MentionList from './MentionList.vue'
import { db } from "@/localdatabase/dbConfig";
export default {
  items: async ({ query }) => {
    const finalArray = [];
    let members = [];
    if (query != "") {
      members = await db.members.where("email").startsWithIgnoreCase(query).or("name").startsWithIgnoreCase(query).or("first_name").startsWithIgnoreCase(query).or("last_name").startsWithIgnoreCase(query).toArray();
    }
    else {
      members = await db.members.toArray();
    }
    const userIds = [];
    const membersArray = [];
    for (let mem of members) {
      const item = {
        name: (mem["first_name"] == "" && mem["last_name"] == "") || (mem["first_name"] == null && mem["last_name"] == null) ? mem["name"] : mem["first_name"] + " " + mem["last_name"],
        id: mem.user_id,
        type: 'members',
        avatar: mem.avatar
      }
      if (!userIds.includes(item.id)) {
        finalArray.push(item);
        userIds.push(item.id);
      }
    }

    let projects = [];
    let projectsArray = [];
    if (query != "") {
      projects = await db.projects.where("name").startsWithIgnoreCase(query).toArray();
    }
    else {
      projects = await db.projects.toArray();
    }
    const projectIds = [];
    for (let pro of projects) {
      const item = {
        name: pro['name'],
        id: pro.id,
        type: 'projects'
      }
      if (!projectIds.includes(item.id)) {
        finalArray.push(item);
        projectIds.push(item.id);
      }
    }

    let issues = [];
    let issuesArray = [];
    if (query != "") {
      issues = await db.issues.where("title").startsWithIgnoreCase(query).toArray();
    }
    else {
      issues = await db.issues.toArray();
    }
    const issueIds = [];
    for (let issue of issues) {
      const item = {
        name: issue['identifier'] + " " + issue['title'],
        id: issue.id,
        type: 'issues',
        status: issue.issuestatus,
        identifier: issue.identifier
      }
      if (!issueIds.includes(item.id)) {
        finalArray.push(item);
        issueIds.push(item.id);
      }
    }


    return finalArray;
  },

  render: () => {
    let component
    let popup

    return {
      onStart: props => {
        component = new VueRenderer(MentionList, {
          // using vue 2:
          // parent: this,
          // propsData: props,
          // using vue 3:
          props,
          editor: props.editor,
        })

        if (!props.clientRect) {
          return
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        popup[0].destroy()
        component.destroy()
      },
    }
  },
}
